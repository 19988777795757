import React, { useEffect, useState } from 'react';
import {
  Card,
  OptionList,
  Modal,
  TextContainer
} from '@shopify/polaris';

import { useCreateModal, Stack } from "admin-frontend";

export function RulesetSidebar({ rulesets, title = "Global Display Rules", resourceName, selected = [], setSelected, element, getElement = () => { return null; } }) {
  const createModal = useCreateModal();

  return (<Card title={title}>
    <OptionList
      selected={selected}
      onChange={(val) => {
        var added = val.filter((id) => !selected.includes(id))[0];
        if (added) {
          const addedRuleset = rulesets.filter((r) => r.id == added)[0];
          const currentElement = getElement(addedRuleset);
          if (currentElement && currentElement.id != element.id) {
            createModal({
              title: "Overwrite " + resourceName.singular + " for this display rule?",
              secondaryActions: [{ content: "Close" }],
              primaryAction: { content: "Confirm", onAction: () => {
                setSelected(val);
              }},
              render: () => {
                return (<Modal.Section>
                  <TextContainer>
                    <p>
                      This display rule is already set to use a specific {resourceName.singular}; "{currentElement.name || currentElement.handle || currentElement.label || currentElement.id}". Are you sure you want to overwrite this display rule's {resourceName.singular}?
                    </p>
                  </TextContainer>
                </Modal.Section>);
              }
            });
          } else
            setSelected(val);
        } else
          setSelected(val);
      } }
      options={rulesets.map((ruleset, idx1) => {
        const otherElement = getElement(ruleset);
        const hasOtherElement = otherElement && otherElement.id != element.id;
        return { label: hasOtherElement ? (<span style={{ color: "#aaaaaa" }}>{ruleset.handle}</span>) : ruleset.handle, value: ruleset.id }
      })}
      allowMultiple
    />
  </Card>);
}

