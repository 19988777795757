import React, {useState, useEffect} from 'react';
import {
  Card,
  Page,
  SkeletonPage,
  Spinner
} from '@shopify/polaris';
import { Auth, Redirect } from "admin-frontend";
import { useHistory, useLocation } from "react-router-dom";
import { useSpotAPI } from "./components/API";

export function Waiting() {
  const [spotInitialized, setSpotInitialized] = useState(false);
	const [profile, , invalidateProfile] = Auth.useProfile();
	const [wasDisabled, setWasDisabled] = useState(false);
	const location = useLocation();
	const history = useHistory();
  const spotAPI = useSpotAPI();

	useEffect(() => {
	  let interval = null;
	  let init = spotInitialized;
	  if (profile && profile.shop.disabled) {
      setWasDisabled(true);
      let checkIndex = () => invalidateProfile();
      interval = setInterval(checkIndex, 5000);
      return () => {
        if (interval)
          clearInterval(interval);
      }
    } else if (profile && !profile.shop.ready && !spotInitialized) {
  	  setSpotInitialized(true);
  	  init = true;
  	}
  	if (init) {
  	  let checkIndex = () => {
  	    let deferred = spotAPI.Deferred();
        spotAPI.s().rows("1").fields(["id"]).e().done(function() {
          invalidateProfile();
          deferred.resolve();
        }).fail(() => { deferred.reject(); });
        return deferred;
      }
      interval = setInterval(checkIndex, 5000);
      return () => {
        if (interval)
          clearInterval(interval);
      }
    }
	}, [spotInitialized, history, profile, spotAPI, invalidateProfile]);

  useEffect(() => {
    if (wasDisabled)
      window.location.reload();
  }, [wasDisabled]);

  if (profile && profile.shop && profile.shop.disabled) {
    return (<Page>
    	<Card sectioned title="Interface Temporary Unavailable">
    	  <p>Hi!</p>
    	  <br/>
    	  <p>The control panel for your store has been temporarily disabled. This is a manual action taken by our team that can happen when we're testing a big update, or if there's some sort of error on your store that would be made worse by editing settings.</p>
    	  <br/>
    	  <p>In the latter case, if it is specific to your store, our team will have reached out to you to work with you to resolve the error.</p>
    	  <br/>
    	  <p>Chances are, this interface will be re-enabled shortly. If you think that think that this is happening in error, or want more information, please give us a shout! You can reach us by either emailing <a href='mailto:spot@moddapps.com'>spot@moddapps.com</a>, or by hitting the chat button in the bottom right corner of your screen.</p>
    	</Card>
    </Page>);
  }


  if (wasDisabled)
    return <></>;


	if (profile && !profile.shop.active_subscription)
    return <Redirect to="/account"/>
  if (profile && profile.shop.ready) {
    if (profile.shop.quickstart.active)
      return <Redirect to="/setup/quick_start"/>
    if (location.pathname === "/" && profile.shop.ready)
      return <Redirect to="/reporting"/>
    return <Redirect to={location.pathname + location.search}/>
  }

	if (spotInitialized) {
  	return (<Page>
    	<Card sectioned title={<h2 className='Polaris-Heading'>We're Indexing your Products! &nbsp;&nbsp;<Spinner size='small'/></h2>}>
    	  <p><b>It'll take us a few moments to index your product catalog.</b> Because a lot of our options relate to letting you access your catalog, we can't quite let you into the app proper until we're done.</p>
    	  <br/>
        <p>For smaller product catalogs, this should be very quick; at most five minutes. If you have a larger catalog, it could take some time, depending on how large your store's catalog is.</p>
        <br/>
        <p>This page will check on the status of the indexing, and redirect you to the app's quick start after everything's been indexed. Sit tight!</p>
        <br/>
        <p>If you like, you can close this page, and we will still keep working on your catalog; and <b>you can come back later</b>.</p>
        <br/>
        <p>Our support team is always up for discussing whatever your needs might be, and how Spot can support them; so please, feel free to reach out to us through the chat in the bottom right corner of the screen, or via email at <a href='mailto:spot@moddapps.com'>spot@moddapps.com</a>! Setup is free!</p>
    	</Card>
    </Page>);
  } else {
    return <SkeletonPage/>
  }
}
