import React, {useState, useEffect, useCallback} from 'react';
import { MultiTableForm, MultiTable, rearrangeArray, useRedirect, useCreateModal } from "admin-frontend";
import {
  Layout,
  Select,
  Button,
  ButtonGroup,
  Tooltip,
  TextField,
  Icon,
  Card,
  Modal,
  TextContainer,
  OptionList
} from '@shopify/polaris';
import { RiskMajor } from '@shopify/polaris-icons';
import { useSpotFetch } from "../../useSpotFetch";
import { RulesetSidebar } from "../../components/RulesetSidebar";
import { ActivityProvider, ActivityBanner } from "../../components/Activity";
import { Stack } from "admin-frontend";

import { Page } from "../../components/Page"
import { localeFormat, LocalizedBadgeList } from "../../components/Localization";
import { useParams } from "react-router-dom";

export function FacetGroups({ setQuickStartStatus, quickStartStatus, themes }) {
  const [facetGroups, setFacetGroups] = useState(null);
  const [facets, setFacets] = useState(null);
  const [rulesets, setRulesets] = useState(null);
  const [facetGroupCounts, setFacetGroupCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [theme, setTheme] = useState(null);

  const authFetch = useSpotFetch();
  const redirect = useRedirect();
  const [selectedRulesets, setSelectedRulesets] = useState([]);
  const { facetGroupId } = useParams();
  const [editingGroup, setEditingGroup] = useState(null);
  const [editingError, setEditingError] = useState({});
  const [editingSelectedFacet, setEditingSelectedFacet] = useState(null);
  const [activity, setActivity] = useState({});
  const createModal = useCreateModal();

  useEffect(() => {
    if (!facetGroups) {
      authFetch("/api/facets/groups", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setFacetGroups(r.groups);
          setFacetGroupCounts(r.counts);
          setActivity(r.activity);
        });
    }
  }, [facetGroups, theme, authFetch]);

  useEffect(() => {
    if (!facets) {
      authFetch("/api/facets", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setFacets(r.facets);
        });
    }
  }, [facets, theme, authFetch]);


  useEffect(() => {
    if (!facets) {
      authFetch("/api/global/rulesets", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setRulesets(r.rulesets);
        });
    }
  }, [rulesets, theme, authFetch]);

  useEffect(() => {
    setIsLoading(!facets || !facetGroups || !rulesets);
  }, [facets, facetGroups, rulesets]);

  const deleteFacetGroups = useCallback((ids) => {
    return authFetch("/api/facets/groups", { method: "DELETE", json: { id: ids } })
      .then((r) => {
        setIsLoading(false);
        var removed = Object.fromEntries(ids.map((e) => [e, 1]));
        setFacetGroups(facetGroups.filter((f) => !removed[f.id]));
      });
  }, [authFetch, facetGroups])

  const needsToRedirect = facetGroups && facets && ((facetGroupId && (!editingGroup || editingGroup.id != facetGroupId || (facetGroupId == "new" && editingGroup.id)) || (!facetGroupId && editingGroup)));

  useEffect(() => {
    if (needsToRedirect) {
      const selectedFacetGroup = facetGroupId && facetGroupId != "new" && facetGroups.filter((fg) => fg.id == facetGroupId)[0];
      setEditingGroup(facetGroupId ? (facetGroupId == "new" ? { facets: [], ruleset_ids: [] } : { ...selectedFacetGroup, facets: selectedFacetGroup.facets.map((id) => facets.filter((f) => f.id == id)[0]) }) : null);
      setSelectedRulesets(rulesets ? rulesets.filter((r) => r.facet_group_id == facetGroupId).map((r) => r.id) : []);
    }
  }, [facetGroups, facets, facetGroupId, needsToRedirect]);

  const facetHash = facets && Object.fromEntries(facets.map((f) => [f.id, f]));
  const facetGroupHash = facetGroups && Object.fromEntries(facetGroups.map((f) => [f.id, f]));
  let rulesetHash = {};
  rulesets && rulesets.forEach((r) => {
    if (!rulesetHash[r.facet_group_id])
      rulesetHash[r.facet_group_id] = [];
    rulesetHash[r.facet_group_id].push(r);
  });

  return (
    <Page
      title="Facet Groups"
      isChanged={isChanged}
      subtitle="Organize your facets into ordered groups to present to your customers."
      audit={editingGroup && {resource: "FacetGroup", id: editingGroup.id}}
      resourceId={editingGroup && editingGroup.id}
      onBack={editingGroup && (() => {
        redirect("/groups");
        setIsChanged(false);
      })}
      resourceName={{
        singular: "facet group",
        plural: "facet groups"
      }}
      permission="facets"
      themes={themes}
      setTheme={(newTheme) => { if (!isLoading && newTheme !== theme) { setIsLoading(true); setTheme(newTheme); setFacets(null); setFacetGroups(null);} }}
      overrideThemes
      disabled={isLoading || needsToRedirect}
      getResourceCount={(themeId) => facetGroupCounts[themeId || 0]}
      onSave={editingGroup && (() => {
        setIsLoading(true);
        authFetch("/api/facets/groups/" + (editingGroup.id || ""), { json: {
          ...editingGroup,
          facets: editingGroup.facets.map((f) => f.id)
        } }).then((r) => {
          setQuickStartStatus({ ...quickStartStatus, facet_groups: true });
          if (editingGroup.id)
            setFacetGroups(facetGroups.map((group, idx) => { return group.id === editingGroup.id ? r : group }))
          else
            setFacetGroups([...facetGroups, r]);
          setIsChanged(false);
          redirect("/groups");
        });
      }) }
      onPort={(sourceId, targetId, preserve) => {
        setIsLoading(true);
        authFetch("/api/facets/port", { json: { preserve: preserve, from: sourceId, to: targetId } })
          .then((r) => {
            setFacets(null);
            setTheme(targetId);
            setIsLoading(false);
          });
      }}
      onDelete={(theme) => {
        deleteFacetGroups(facetGroups.map((f) => f.id))
          .then((r) => {
            setFacetGroups(null);
            setTheme(0);
            setIsLoading(false);
        });
      }}
    >
      {(currentTheme, currentLocale) => {
        let validFacets = facets && facets.filter((e) => !e.locales || e.locales.length === 0 || e.locales.filter((l) => l === currentLocale ? currentLocale.code : "en").length > 0);
        const remainingFacets = validFacets && editingGroup && validFacets.filter((facet,idx) => { return !editingGroup.facets.includes(facet) });
        return (
          <Layout>
            <Layout.Section>
              <ActivityProvider activity={activity} objectType={"facet_group"} setActivity={setActivity}>
                <MultiTableForm
                  isEditing={editingGroup}
                  hideTable={editingGroup}
                  onNew={() => {
                    redirect("/groups/new")
                  }}
                  onRowClick={(row, idx) => {
                    redirect("/groups/" + facetGroups[idx].id);
                  }}
                  resourceName={{
                    singular: "facet group",
                    plural: "facet groups"
                  }}
                  bulkActions={[{
                    content: "Delete facet groups",
                    onAction: (ids) => deleteFacetGroups(ids.map((idx) => facetGroups[idx].id))
                  }]}
                  editingForm={editingGroup && [
                    (<TextField
                      key="name"
                      label="Name"
                      value={editingGroup.name}
                      onChange={(val) => { setIsChanged(true); setEditingGroup({ ...editingGroup, name: val }); }}
                      error={editingError.name}
                    />),
                    (<TextField
                      key="description"
                      multiline={4}
                      label="Description"
                      placeholder="Optional freeform description for this facet group. Does not affect functionality."
                      value={editingGroup.description}
                      onChange={(val) => { setIsChanged(true); setEditingGroup({ ...editingGroup, description: val }); }}
                    />),
                    (validFacets && validFacets.length > 0 && editingGroup.facets.length < validFacets.length && <Stack>
                      <Select
                        options={remainingFacets.map((facet, idx) => { return { label: typeof(facet.name) === "object" ? (facet.name[currentLocale ? currentLocale.code : Object.keys(facet.name)[0]] || facet.name["en"]) : facet.name, value: '' + idx } })}
                        onChange={setEditingSelectedFacet}
                        value={editingSelectedFacet || "0"}
                      />
                      <Button
                        primary
                        onClick={() => {
                          setEditingGroup({ ...editingGroup, facets: [...editingGroup.facets, remainingFacets[parseInt(editingSelectedFacet || "0")]] })
                          setEditingSelectedFacet(null)
                          setIsChanged(true);
                        }}
                      >
                        Add
                      </Button>
                    </Stack>),
                    (<MultiTable
                      bulkActions={[
                        {
                          content: "Remove",
                          onAction: (items) => {
                            setIsChanged(true);
                            if (typeof items !== "object") items = [items];
                            setEditingGroup({
                              ...editingGroup,
                              facets: editingGroup.facets.filter((fv, idx) => !items.includes(idx))
                            });
                            setIsChanged(true);
                          },
                        },
                      ]}
                      onDragDrop={(source, destination) => {
                        setEditingGroup({ ...editingGroup, facets: rearrangeArray(editingGroup.facets, source, destination) });
                        setIsChanged(true);
                      }}
                      resourceName={{
                        singular: "facet",
                        plural: "facets"
                      }}
                      headings={[
                        "Facet"
                      ]}
                      rows={editingGroup && editingGroup.facets && editingGroup.facets.map((facet, idx) => { return [
                        typeof(facet.name) === "object" ? (facet.name[currentLocale ? currentLocale.code : Object.keys(facet.name)[0]] || facet.name["en"]) : facet.name,
                      ] })}
                    />)
                  ]}
                  headings={[
                    "Facet Group",
                    "Constituent Facets",
                    "Applied Display Rules"
                  ]}
                  rows={facetGroups && facetGroups.map((facetGroup, idx) => { return [
                    (<Stack alignment="center">
                      <Stack.Item fill>
                        {typeof(facetGroup.name) === "object" ? (facetGroup.name[currentLocale ? currentLocale.code : Object.keys(facetGroup.name)[0]] || facetGroup.name["en"]) : facetGroup.name}
                      </Stack.Item>
                      <ActivityBanner activity={activity[facetGroup.id]} objectId={facetGroup.id}/>
                    </Stack>),
                    (<LocalizedBadgeList locale={currentLocale} maxToDisplay={5} values={facetHash && facetGroup.facets.map((id) => facetHash[id].name)}/>),
                    (<LocalizedBadgeList locale={currentLocale} values={rulesetHash && rulesetHash[facetGroup.id] && rulesetHash[facetGroup.id].map((r) => r.handle)} emptyTooltip="Because this facet group is not part of any display rule, it will never be shown on the customer-facing part of your website. To use this facet group, set it as part of a display rule on the display rules page, under 'Merchandising'."/>)
                  ] })}
                />
              </ActivityProvider>
            </Layout.Section>
            {editingGroup && rulesets && <Layout.Section secondary>
              <RulesetSidebar
                rulesets={rulesets}
                resourceName={{ singular: "personalization" }}
                selected={editingGroup.ruleset_ids}
                setSelected={(ids) => { setEditingGroup({ ...editingGroup, ruleset_ids: ids }); setIsChanged(true); }}
                element={editingGroup}
                getElement={(ruleset) => { return ruleset.facet_group_id && facetGroupHash[ruleset.facet_group_id]; }}
              />
            </Layout.Section>}
          </Layout>
        )
      }}
    </Page>
  );
}
