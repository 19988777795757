import React, { useContext, createContext, useState, useCallback, useEffect } from "react";
import { useRedirectFetch } from "admin-frontend";

import { SpotState } from "./API";

export const StatusContext = createContext(null);

export const useStatus = () => {
  const status = useContext(StatusContext);
  if (status)
    return status;
  return [];
};

export function StatusContextProvider({ children }) {
  const [status, setStatus] = useState(null);
  const [synchingInterval, setSynchingInterval] = useState(null);
  const authFetch = useRedirectFetch();

  let refreshStatus;
  refreshStatus = useCallback(function() {
    authFetch('/api/setup/status').then((r) => {
      setStatus(r.status);
    }, () => { });
  }, [authFetch, setStatus]);

  useEffect(() => {
    refreshStatus();
  }, [refreshStatus]);


  const isSynching = status && status.synching;

  useEffect(() => {
    if (isSynching && !synchingInterval) {
      setSynchingInterval(setInterval(() => {
        refreshStatus();
      }, status.synching.interval*1000));
    } else if (!isSynching && synchingInterval) {
      clearInterval(synchingInterval);
      setSynchingInterval(null);
    }
  }, [isSynching]);

  return (<SpotState><StatusContext.Provider value={[status, refreshStatus]}>
    {children}
  </StatusContext.Provider></SpotState>);
}
