import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/dist/styles.css';
import 'admin-frontend/dist/esm/styles.css';

import { App, Redirect, InstallPage, useRedirect, PermissionsPage, Stack, Billing } from "admin-frontend";
import jwtDecode from "jwt-decode";
import {
  Page,
  Card,
  Icon
} from '@shopify/polaris';

import { ProductSplits } from './Tabs/GlobalProperties/ProductSplits';
import { ProductMerges } from './Tabs/GlobalProperties/ProductMerges';
import { CatalogProcessing } from './Tabs/CatalogProcessing';
import { ArtificialIntelligence } from "./Tabs/ArtificialIntelligence";
import { SortOptions } from './Tabs/GlobalProperties/SortOptions';
import { MetafieldPolicy } from './Tabs/GlobalProperties/MetafieldPolicy';
import { CustomFields } from './Tabs/GlobalProperties/CustomFields';
import { ProductWeights } from './Tabs/GlobalProperties/ProductWeights';
import { Facets } from './Tabs/Facets/Facets';
import { EditFacet } from './Tabs/Facets/EditFacet';
import { OrphanedFacets } from './Tabs/Facets/OrphanedFacets';
import { FacetGroups } from './Tabs/Facets/FacetGroups';
import { SynonymGroups, OneWaySynonyms, SearchWeights, Personalizations, Redirects, SearchPins, SearchOverrides, PopularSearches, SearchConstants } from './Tabs/SearchOptions';
import { Recommendations } from './Tabs/Recommendations';
import { Badges, BadgeGroups } from './Tabs/Badges';
import { Banners } from './Tabs/Banners';
import { Swatches } from "./Tabs/Swatches";
import { Reservations } from './Tabs/Reservations';
import { Bundles } from './Tabs/Bundles';
import { BoostRules } from './Tabs/BoostRules';
import { Reporting, Explorer, Journeys } from './Tabs/Reporting';
import { Subscriptions } from './Tabs/Subscriptions';
import { SetupPreview, SetupTheme, SetupStatus, SetupClone, SetupAudit } from './Tabs/Setup';
import { CollectionDetails, CollectionRulesets } from './Tabs/CollectionDetails';
import { Rulesets, Scheduling } from './Tabs/GlobalProperties/Ruleset';
import { Waiting } from './Waiting';
import { Legal, TermsOfService } from "./Tabs/Legal";
import { Integrations } from "./Tabs/Integrations";
import { QuickStart, QuickStartBar } from './Tabs/QuickStart';
import { StatusContextProvider } from "./components/StatusContext";
import { IconMap } from "./components/IconMap";
import { NotFound } from './NotFound';

import "./index.css"

import { AddCodeMajor, SortAscendingMajor, AnalyticsMajor, SettingsMajor, SearchMajor, DragDropMajor, DnsSettingsMajor, ProfileMajor,
ExchangeMajor, MaximizeMajor, TipsMajor, CustomersMajor, DesktopMajor, CollectionsMajor, ViewportWideMajor, ThumbsUpMajor, DetailedPopUpMajor, LockMajor,
ThemesMajor, StoreStatusMajor, OutgoingMajor, LegalMajor, PinMinor, AffiliateMajor, Columns3Major, ExternalMinor, ViewportNarrowMajor,
IconsMajor, PinMajor, ReplaceMajor, CircleInformationMajor, PageMajor, ChatMajor, VocabularyMajor, KeyMajor, PopularMajor, CategoriesMajor, GiftCardMajor, EmailNewsletterMajor, ChannelsMajor } from '@shopify/polaris-icons';

const urlParams = new URLSearchParams(window.location.search);

const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;

let urlToken = urlParams.get("token");
const embedded = !urlParams.has("not_embedded") && !urlToken && urlParams.has("shop");
const shopOrigin = urlToken ? jwtDecode(urlToken).dest.replace(/^[^/:*]:\/\//, '') : urlParams.get("shop");
const host = urlParams.get("host");

if (!embedded && urlToken)
  sessionStorage.setItem("token", urlToken)
else if (!embedded)
  urlToken = sessionStorage.getItem("token");

function AppComponent({ apiKey, host, shopOrigin, embedded, urlToken }) {
  const [quickStartStatus, setQuickStartStatus] = useState(null);
  const [themes, setThemes] = useState(null);
  const [profile, setProfile] = useState(null);



  let routes = useMemo(() => {
    return (profile && profile.shop.ready && !profile.shop.disabled ? [
      { path: "/login", access: "anonymous", render: () => <Page><Card title="Install/Login to Spot on your Store" sectioned><InstallPage/></Card></Page> },
      { path: "/collections", hideTitleBar: true, render: () => <CollectionDetails/> },
      { path: "/facets/orphaned", render: () => <OrphanedFacets themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/> },
      { path: "/facets/:facetId", render: () => <EditFacet  themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/> },
      { path: "/globals/badges/groups/:badgeGroupId", render: () => <Badges/> },
      { path: "/globals/banners/:bannerId", render: () => <Banners/> },
      { path: "/globals/badges/:badgeId", render: () => <Badges/> },
      { path: "/groups/:facetGroupId", render: () => <FacetGroups themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/> },
      { path: "/journeys/:journeyId", render: () => <Journeys/> },
      { path: "/setup/integrations/:integration", render: () => <Integrations/> },
      { path: "/globals/boost_rules/:boostRuleId", render: () => <BoostRules/> },
      { path: "/globals/recommendations/:recommendationId", render: () => <Recommendations/> },
      { path: '/globals/rulesets/:rulesetId', render: () => <Rulesets themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/> },
      { path: '/search/personalizations/:personalizationId', render: () => <Personalizations themes={themes}/> },
      {
        name: 'Product Information',
        icon: AddCodeMajor,
        items: [{
          name: "Facets",
          icon: DragDropMajor,
          path: "/facets",
          render: () => <Facets themes={themes} />
        }, {
          name: "Facet Groups",
          icon: DetailedPopUpMajor,
          path: "/groups",
          render: () => <FacetGroups themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/>
        }, {
          name: 'Sort Options',
          icon: SortAscendingMajor,
          path: '/globals/sort_options',
          render: () => <SortOptions themes={themes}/>
        }, {
          name: 'Custom Fields',
          icon: AddCodeMajor,
          path: '/globals/fields',
          render: () => <CustomFields/>
        }, {
          name: 'Product Weights',
          icon: TipsMajor,
          path: '/globals/product_weights',
          render: () => <ProductWeights/>
        }, {
          name: 'Color Swatches',
          icon: IconsMajor,
          path: '/globals/swatches',
          render: () => <Swatches themes={themes}/>
        }]
      },
      { name: "Merchandising", icon: SettingsMajor, items: [{
          name: 'Display Rules',
          icon: DesktopMajor,
          path: '/globals/rulesets',
          render: () => <Rulesets themes={themes} quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus}/>
        }, {
          name: 'Smarter Collections',
          icon: CollectionsMajor,
          path: '/globals/collections',
          render: () => <CollectionRulesets themes={themes}/>
        }, /*{
          name: 'Scheduling',
          icon: CollectionsMajor,
          path: '/globals/scheduling',
          render: () => <Scheduling/>
        }*/, {
          name: 'Product Splits',
          icon: ViewportWideMajor,
          path: '/globals/splits',
          render: () => <ProductSplits themes={themes}/>
        }, {
          name: 'Product Merges',
          icon: ViewportNarrowMajor,
          path: '/globals/merges',
          render: () => <ProductMerges themes={themes}/>
        }, {
          name: "Boost Rules",
          icon: SortAscendingMajor,
          path: "/globals/boost_rules",
          render: () => <BoostRules/>
        }, {
          name: "Recommendations",
          icon: ThumbsUpMajor,
          path: "/globals/recommendations",
          render: () => <Recommendations/>
        }, {
          name: "Badges",
          icon: CategoriesMajor,
          path: "/globals/badges",
          render: () => <Badges/>
        },/* {
          name: "Banners",
          icon: CategoriesMajor,
          path: "/globals/banners",
          render: () => <Banners/>
        }*/
      ]},
      { name: "Search Options", icon: SearchMajor, items: [
        {
          name: 'Synonym Groups',
          icon: ExchangeMajor,
          path: '/search/synonym_groups',
          render: () => <SynonymGroups/>
        }, {
          name: 'One-Way Synonyms',
          icon: MaximizeMajor,
          path: '/search/one_way_synonyms',
          render: () => <OneWaySynonyms/>
        }, {
          name: 'Field Weights',
          icon: TipsMajor,
          path: '/search/search_weights',
          render: () => <SearchWeights/>
        },  {
          name: 'Constants',
          icon: TipsMajor,
          path: '/search/search_constants',
          render: () => <SearchConstants/>
        },  {
          name: 'Personalizations',
          icon: CustomersMajor,
          path: '/search/personalizations',
          render: () => <Personalizations themes={themes}/>
        }, {
          name: 'Redirects',
          icon: ExternalMinor,
          path: '/search/redirects',
          render: () => <Redirects/>
        }, {
          name: 'Popular Searches',
          icon: ExternalMinor,
          path: '/search/popular',
          render: () => <PopularSearches/>
        },  {
          name: 'Pins',
          icon: PinMajor,
          path: '/search/pins',
          render: () => <SearchPins/>
        },  {
          name: 'Overrides',
          icon: ReplaceMajor,
          path: '/search/overrides',
          render: () => <SearchOverrides/>
        }, {
          name: "Preview",
          icon: SearchMajor,
          path: '/setup/preview',
          render: () => <SetupPreview/>
        }
      ] },
      { name: "Analytics", icon: AnalyticsMajor, items: [
        { name: "Dashboard", icon: Columns3Major, path: "/reporting", render: () => <Reporting /> },
        { name: "Explorer", icon: AffiliateMajor, path: "/explorer", render: () => <Explorer /> },
        { name: "Journeys", icon: PopularMajor, path: "/journeys", render: () => <Journeys /> }
      ]},
      { name: "Setup", icon: DnsSettingsMajor, items: [
        {
          name: "Account",
          icon: ProfileMajor,
          path: "/account",
          exact: true,
          render: () => <Subscriptions termsOfService={<TermsOfService/>} termsOfServiceURL={"/setup/legal"}/>
        }, {
          name: "Bundles",
          icon: GiftCardMajor,
          path: "/account/bundles",
          render: () => <Bundles/>
        }, {
          name: 'Catalog Processing',
          icon: SettingsMajor,
          path: '/setup/catalog',
          render: () => <CatalogProcessing/>
        }, {
          name: 'Artifical Intelligence',
          icon: ChannelsMajor,
          path: '/setup/ai',
          render: () => <ArtificialIntelligence/>
        }, {
          name: 'Metafield Policy',
          icon: LockMajor,
          path: '/globals/metafields',
          render: () => <MetafieldPolicy/>
        }, {
          name: 'Theme Integration',
          icon: ThemesMajor,
          path: '/setup/theme',
          render: () => <SetupTheme quickStartStatus={quickStartStatus} setQuickStartStatus={setQuickStartStatus} themes={themes}/>
        }, {
          name: 'External Integrations',
          icon: ThemesMajor,
          path: '/setup/integrations',
          render: () => <Integrations themes={themes}/>
        },  {
          name: "Status",
          icon: StoreStatusMajor,
          path: '/setup/status',
          render: () => <SetupStatus/>
        }, ...(profile.shop.scalable ? [{
          name: "Reservations",
          icon: PinMinor,
          path: '/setup/reservations',
          render: () => <Reservations/>
        }] : []), ...(profile.user.other_shops && Object.keys(profile.user.other_shops).length > 0 ? [{
          name: "Cloning",
          icon: PinMinor,
          path: '/setup/cloning',
          render: () => <SetupClone/>
        }] : []), {
          name: "Permissions",
          icon: KeyMajor,
          path: '/setup/permissions',
          render: () => <PermissionsPage/>
        }, {
          name: "Quick Start Wizard",
          icon: OutgoingMajor,
          path: '/setup/quick_start',
          render: () => <QuickStart themes={themes} status={quickStartStatus} setStatus={setQuickStartStatus}/>
        }, {
          name: "Audit",
          icon: StoreStatusMajor,
          path: '/setup/audit',
          render: () => <SetupAudit/>
        }, {
          name: "Legal",
          icon: LegalMajor,
          path: '/setup/legal',
          render: () => <Legal/>
        },
      ]},
      { name: "Help", icon: CircleInformationMajor, items: [
        {
          name: "Quick Start Guide",
          icon: PageMajor,
          url: "https://" + window.location.hostname + "/docs/quickstart"
        },
        {
          name: "Change Log",
          icon: EmailNewsletterMajor,
          url: "https://" + window.location.hostname + "/docs/changelog"
        },
        /* Removed this until Jeff actually writes it.
        {
          name: "User Guide",
          icon: PageMajor,
          url: "https://support.moddapps.com/hc/en-us/categories/360005049294-Spot"
        },*/
        {
          name: "Developer Docs",
          icon: VocabularyMajor,
          url: "https://" + window.location.hostname + "/docs"
        },
        ...(profile.user.support ? [{
          name: "Icon Map",
          path: '/help/icons',
          icon: VocabularyMajor,
          render: () => {
            return <IconMap/>
          }
        }] : []),
        {
          name: "Contact Us",
          icon: ChatMajor,
          onAction: () => {
            if (window.zE) {
              window.zE.activate();
            } else {
              let b64 = btoa(
                encodeURIComponent(
                  JSON.stringify({
                    webWidget: {
                      chat: {},
                      contactForm: {},
                    },
                  })
                )
              );
              if (window.zESettings) {
                b64 = btoa(encodeURIComponent(JSON.stringify(window.zESettings)));
              }
              window.open(
                `https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=moddapps.zendesk.com&settings=${b64}&locale=en-ca&title=Web%20Widget%20Live%20Chat`,
                'ChatWindow',
                'width=400,height=675'
              );
            }
          }
        }
      ]},
      { path: "/", exact: true, render: () => <Waiting/> },
      { path: "*", render: () => <NotFound/> }
    ] : [
        { path: "/login", access: "anonymous", render: () => <Page><Card title="Install/Login to Spot on your Store" sectioned><InstallPage/></Card></Page> },
        ...(profile && !profile.shop.active_subscription ? [{ icon: ProfileMajor, name: "Account", path: "/account", render: () => <Subscriptions termsOfService={<TermsOfService/>} termsOfServiceURL={"/setup/legal"}/>  }] : []),
        { name: "Legal",  access: "all", icon: LegalMajor, path: '/setup/legal', render: () => <Legal/> },
        { ...(profile && profile.shop.active_subscription && profile.shop.disabled ? { name: "Status" } : {}), icon: SettingsMajor, path: "/", render: () => <Waiting/> },
        { exact: false, access: "anonymous", path: "/", render: () => <Redirect to="https://moddapps.com/pages/spot"/> }
    ]);
  }, [profile,  apiKey, host, shopOrigin, embedded, urlToken, themes, quickStartStatus]);

  return (<App
      apiKey={apiKey}
      host={host}
      shopOrigin={shopOrigin}
      embedded={embedded}
      urlToken={urlToken}
      routes={[{ path: "/subscriptions/onetime", access: "all", render: () => <Billing/> }, ...routes]}
      onProfileIsKnown={(profile, authFetch) => {
        if (profile) {
          setProfile(profile);
          if (profile.shop.ready && profile.shop.active_subscription) {
            if (!themes) {
              authFetch("/api/setup/themes")
                .then((r) => r.json())
                .then((r) => {
                  setThemes(r.themes || []);
                  setQuickStartStatus({ ...(quickStartStatus ? quickStartStatus : {}), ...(profile.shop.quickstart ? profile.shop.quickstart : {}) });
              });
            }
          }
        }
      }}
      globalContextComponent={StatusContextProvider}
    >
      {quickStartStatus && quickStartStatus.active && profile && profile.shop && profile.shop.active_subscription  && profile.shop.ready && <QuickStartBar status={quickStartStatus} setStatus={setQuickStartStatus} />}
    </App>);
}

ReactDOM.render(
  <React.StrictMode>
    <AppComponent
      apiKey={apiKey}
      host={host}
      shopOrigin={shopOrigin}
      embedded={embedded}
      urlToken={urlToken}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

