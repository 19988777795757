import React, {useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Card,
  ChoiceList
} from '@shopify/polaris';
import { useLocation } from "react-router-dom";
import { Auth, useRedirect } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"

export function CatalogProcessing() {
  const [profile] = Auth.useProfile();
  const redirect = useRedirect();
	const [isLoading, setIsLoading] = useState(true);
  const [processOrders, setProcessOrders] = useState(null);
  const [storefrontAccess, setStorefrontAccess] = useState(null);
  const [syncUnpublishedCollections, setSyncUnpublishedCollections] = useState(null);
  const [selectedSources, setSelectedSources] = useState (["products", "collections"]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [autoUpdated, setAutoUpdated] = useState(false);

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (syncUnpublishedCollections === null) {
			authFetch("/api/setup/processing")
				.then((r) => {
					setSyncUnpublishedCollections(r.sync_unpublished_collections);
					setProcessOrders(r.process_orders);
					setStorefrontAccess(r.storefront_access);
					setSelectedSources(r.targets);
					setUpdatedAt(r.updated_at);
					setIsLoading(false);
				});
		}
  }, [processOrders, authFetch, syncUnpublishedCollections]);

  const handleSourcesChange = useCallback((value) => {
    setSelectedSources(value);
		setIsLoading(true);
    authFetch("/api/setup/processing", { json: { "targets": value, updated_at: updatedAt } })
      .then((r) => {
        if (r.reauth) {
					authFetch("/api/login", { query: { shop: profile.shop.shop_origin } }).then((r) => {
						redirect(r.url);
					})
        } else
					setIsLoading(false);
        setUpdatedAt(r.updated_at);
      });
  }, [authFetch, redirect, updatedAt]);

  const search = useLocation().search;
  const [params] = useState(new URLSearchParams(search));

	const updateProcessOrders = useCallback((processOrders) => {
		setIsLoading(true);
		authFetch("/api/setup/processing", { json: { "process_orders": processOrders, updated_at: updatedAt } })
			.then((r) => {
				setIsLoading(false);
				setProcessOrders(r.process_orders);
        setUpdatedAt(r.updated_at);
		});
	}, [authFetch, updatedAt]);


	const updateStorefrontAccess = useCallback((storefrontAccess) => {
		setIsLoading(true);
		authFetch("/api/setup/processing", { json: { "storefront_access": storefrontAccess, updated_at: updatedAt } })
			.then((r) => {
				setIsLoading(false);
				setStorefrontAccess(r.storefront_access);
        setUpdatedAt(r.updated_at);
		});
	}, [authFetch, updatedAt]);

	useEffect(() => {
		if (params.get("process_order_update") && updatedAt && !autoUpdated) {
			updateProcessOrders(/orders/.test(profile.shop.scope));
			setAutoUpdated(true);
		}
		if (params.get("storefront_access_update") && updatedAt && !autoUpdated) {
			updateStorefrontAccess(/unauthenticated_read_product_listings/.test(profile.shop.scope));
			setAutoUpdated(true);
		}
		if (params.get("initialize_cache") && updatedAt && !autoUpdated) {
			setIsLoading(true);
			authFetch("/api/setup/processing", { json: { "initialize_cache": true, updated_at: updatedAt } }).then((r) => {
				setAutoUpdated(true);
				setIsLoading(false);
        redirect('/setup/catalog');
			});
		}
	// eslint-disable-next-line
	}, [updatedAt, autoUpdated, params]);

  return (
	<Page
		title="Catalog Processing"
    disableThemes
    permission="setup"
		>
		<Layout>
			<Layout.Section>
				<Card sectioned title="Order Processing" primaryFooterAction={{
				  content: processOrders !== null ? (processOrders ? "Disable Order Processing" : "Activate Order Processing") : "...",
				  disabled: isLoading,
				  onAction: () => {
				  	if ((!processOrders && /orders/.test(profile.shop.scope)) || (processOrders && !/orders/.test(profile.shop.scope))) {
							updateProcessOrders(!processOrders);
				  	} else {
							setIsLoading(true);
							authFetch("/api/setup/authorization", { json: { "process_orders": !processOrders, updated_at: updatedAt } })
								.then((r) => {
									setUpdatedAt(r.updated_at);
									if (r.reauth) {
										authFetch("/api/login", { query: { shop: profile.shop.shop_origin } }).then((r) => {
											redirect(r.url);
										});
									} else
										setIsLoading(false);
							});
						}
	  			}
				}}>
  				<p>If you activate order processing Spot can provide extra functionality like sorting results by bestselling. Spot will not store this data in any way, though it will process it to determine the total of amount of sales that have ocurred on a per-product basis. Changing this setting will redirect you to authorize new permissions.</p>
				</Card>
				<Card sectioned title="Unpublished Collection Processing" primaryFooterAction={{
          content: syncUnpublishedCollections !== null ? (syncUnpublishedCollections ?  "Disable Unpublished Collection Processing" : "Activate Unpublished Collection Processing") : "...",
				  disabled: isLoading,
				  onAction: () => {
				  	setIsLoading(true);
				  	authFetch("/api/setup/processing", { json: { "sync_unpublished_collections": !syncUnpublishedCollections, updated_at: updatedAt } })
				  		.then((r) => {
								setSyncUnpublishedCollections(r.sync_unpublished_collections);
								setIsLoading(false);
								setUpdatedAt(r.updated_at);
			  			});
				  }
				}}>
					<p>If you do activate unpublished collection procesing, Spot will index your unpublished collections. This has the advantage of having your collections always be ready when you publish them, but the disadvantage of potentailly slowing down your overall indexing process.</p>
				</Card>
				<Card sectioned title="Storefront API Access" primaryFooterAction={{
				  content: (profile.shop.storefront_token ? "Disable Storefront Access" : "Enable Storefront Access"),
				  disabled: isLoading,
				  onAction: () => {
				  	if ((!storefrontAccess && /unauthenticated_read_product_listings/.test(profile.shop.scope)) || (storefrontAccess && !/unauthenticated_read_product_listings/.test(profile.shop.scope))) {
							updateStorefrontAccess(!storefrontAccess);
				  	} else {
							setIsLoading(true);
							authFetch("/api/setup/authorization", { json: { "storefront_access": !storefrontAccess, updated_at: updatedAt } })
								.then((r) => {
									setUpdatedAt(r.updated_at);
									if (r.reauth) {
										authFetch("/api/login", { query: { shop: profile.shop.shop_origin } }).then((r) => {
											redirect(r.url);
										})
									} else
									setIsLoading(false);
							});
						}
	  			}
				}}>
  				<p>If supplied, will automatically query Shopify's storefront API, and update most variables on products with information that is currently the most up-to-date. Introduces an additional delay in requests, but will ensure that most properties are 100% up to date.</p>
				</Card>
        <Card sectioned title="Indexed Shopify Data">
					<p>Please be aware that activating any of the follow options below may slow your synching/indexing time slightly. Only activate them if you need to.</p>
          <ChoiceList
            allowMultiple
            disabled={isLoading}
            title="Select the Shopify Data that Spot should make available in search results"
            choices={[
              {
                label: 'Products',
                value: 'products',
                disabled: true,
              },
              {
                label: 'Collections',
                value: 'collections',
                disabled: true,
              },
              {
								label: 'Media',
								value: 'media',
							},
              {
								label: 'Standardized Product Types /  Categories',
								value: 'category',
							},
              {
								label: 'Multi-Location Inventory',
								value: 'inventory',
								helpText: 'Requires read_inventory, read_locations scope',
							},
              {
								label: 'Catalogs',
								value: 'catalogs',
								helpText: 'Requires read_publications scope'
							},
              {
								label: 'Markets / Price Lists',
								value: 'priceLists',
								helpText: 'Requires read_markets scope',
							},
              {
                label: 'Blogs',
                value: 'blogs',
                helpText: 'Requires read_content scope',
              },
              {
                label: 'Articles',
                value: 'articles',
                helpText: 'Requires read_content scope',
              },
              {
                label: 'Pages',
                value: 'pages',
                helpText: 'Requires read_content scope',
              },
            ]}
            selected={selectedSources}
            onChange={handleSourcesChange}
          />
        </Card>
			</Layout.Section>
		</Layout>
  </Page>);
}
