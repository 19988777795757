import React, {useState, useCallback, useEffect} from 'react';
import {
  Layout,
  FormLayout,
  Label,
  TextField,
  Select,
  Button,
} from '@shopify/polaris';
import { MultiTableForm, Auth, useRedirectFetch, Stack } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { Page } from "../../components/Page"
import {ResourcePicker} from "../../components/ResourcePicker"
import { UpgradeBanner } from "../../components/UpgradeBanner";


import { StreamLanguage } from '@codemirror/language';
import CodeMirror from "@uiw/react-codemirror";

export function CustomFields() {
  const authFetch = useSpotFetch();
  const nonErrorFetch = useRedirectFetch();
  const [loaded, setLoaded] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [currentField, setCurrentField] = useState({ handle: "", level: "product", code: ""});
  const [currentHandle, setCurrentHandle] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [newField, setNewField] = useState(true);
  const [duplicateHandle, setDuplicateHandle] = useState(false);
  const [previewProduct, setPreviewProduct] = useState(null);
  const [previewVariant, setPreviewVariant] = useState("");
  const [previewResult, setPreviewResult] = useState("");
  const [previewError, setPreviewError] = useState(null);
  const [resourcePicker, setResourcePicker] = useState(false);

  const [profile] = Auth.useProfile();

  useEffect(() => {
		if (!loaded) {
			authFetch("/api/global/custom_fields")
				.then((r) => {
            setCustomFields(r.custom_fields);
            setUpdatedAt(r.updated_at);
            setLoaded(true);
				});
		}
	}, [authFetch, loaded]);


  const updateCustomFields = useCallback((newCustomFields) => {
    return authFetch("/api/global/custom_fields", { json: { custom_fields: newCustomFields, updated_at: updatedAt } })
    .then((r) => {
        setCustomFields(r.custom_fields);
        setUpdatedAt(r.updated_at);
        setIsChanged(false);
    })
  }, [authFetch, updatedAt])

  const handleCloseModal = useCallback(() => {
    setModalActive(false);
    setCurrentField({ handle: "", level: "product", code: ""});
    setNewField(true);
    setDuplicateHandle(false);
  }, []);

  const handleSaveField = useCallback(({ onSave }) => {
    // Create a new customFields array by either adding the new one or replacing the edited one
    var newCustomFields = newField ? customFields.map(function(e) { return { ...e }; }).concat({ ...currentField })
      : customFields.map((e) => currentHandle === e.handle ? { ...currentField } : e);
    setCustomFields(newCustomFields);
    updateCustomFields(newCustomFields).then((r) => {
      if (onSave)
        onSave();
      handleCloseModal();
    });
  }, [customFields, currentField, currentHandle, newField, handleCloseModal, updateCustomFields]);


  const deleteCustomFields = useCallback((indexes) => {
    const newCustomFields = customFields.filter(function(cf){ return !indexes.includes(customFields.indexOf(cf)) });
    setCustomFields(newCustomFields);
    updateCustomFields(newCustomFields);
  }, [customFields, updateCustomFields])

  const handlePreviewResultFetch = useCallback(() => {
    const variant = previewProduct.variants.filter(function(variant){ return variant.id === previewVariant })[0];
    if (window.runningPreview)
      window.clearTimeout(window.runningPreview);
    window.runningPreview = window.setTimeout(() => {
      setPreviewError(null);
      nonErrorFetch("/api/global/custom_fields/preview", { json: { code: currentField.code, product: previewProduct, variant: variant } })
      .then((response) => {
        setPreviewResult(response.result);
        window.runningPreview = null;
      }, (r) => {
        setPreviewError(r);
      })
    }, 200);

  }, [authFetch, previewProduct, previewVariant, currentField]);

  useEffect(() => {
    if(modalActive && previewProduct)
      handlePreviewResultFetch();
  }, [previewProduct, modalActive, previewVariant, handlePreviewResultFetch]);

  const resourceName= { singular: 'custom field', plural: 'custom fields' };

  const modalMarkup = (
    <Stack distribution="fillEvenly">
      <Stack.Item>
        <FormLayout>
          <TextField
            label="Handle"
            value={currentField.handle}
            onChange={(val) => { setIsChanged(true); setCurrentField({ ...currentField, handle: val }); }}
            helpText="A handle representing a unique identifier for your custom field."
            error={duplicateHandle ? "This handle is already in use" : null}
          />
          <TextField
            label="Description"
            value={currentField.description}
            multiline={4}
            onChange={(val) => { setIsChanged(true); setCurrentField({ ...currentField, description: val }); }}
            value={currentField.description}
            helpText="Optional freeform description for this custom rule. Does not affect functionality."
          />
          <Select
            label="Level"
            options={[{label: "Product", value: "product"}, {label: "Variant", value: "variant"}]}
            onChange={(val) => { setIsChanged(true); setCurrentField({ ...currentField, level: val }); }}
            value={currentField.level}
            helpText="Whether the custom field should be relevant at a product or a variant level."
          />
          <Select
            label="Visibility"
            options={[{label: "Private", value: "private"}, {label: "Public", value: "public"}]}
            onChange={(val) => { setIsChanged(true); setCurrentField({ ...currentField, visibility: val }); }}
            value={currentField.level === "variant" || !currentField.visibility ? "private" : currentField.visibility}
            disabled={currentField.level === "variant"}
            helpText="Determines whether the result of this field will be returned with product data when searching."
          />
          <TextField
            label="Delimiter"
            value={currentField.delimiter}
            onChange={(val) => { setIsChanged(true); setCurrentField({ ...currentField, delimiter: val }); }}
            helpText="An optional delimiter to split your field into multiple values."
          />
          <Stack vertical>
            <Label>Code</Label>
            <CodeMirror value={currentField.code} height={"160px"} onChange={(value) => { setIsChanged(true); setCurrentField({ ...currentField, code: value }) }}/>
          </Stack>
        </FormLayout>
      </Stack.Item>
      <Stack.Item>
        <FormLayout>
          {previewProduct && currentField.level === "variant" ?
            <Select
              options={previewProduct.variants.map((variant) => { return { label: variant.title, value: variant.id } })}
              onChange={(variant) => setPreviewVariant(variant)}
              value={previewVariant}
            /> : null}
          <TextField
            label="Preview Results"
            value={previewResult}
            disabled
            multiline={27}
          />
          <Button
            primary
            fullWidth
            onClick={() => {setResourcePicker(true)}}
          >
            {previewProduct ? previewProduct.title : "Preview a Product"}
          </Button>
        </FormLayout>
      </Stack.Item>
    </Stack>
  )

  const hasReachedLimit = profile && customFields && profile.shop.active_subscription.maximums.custom_field !== null && customFields.length >= profile.shop.active_subscription.maximums.custom_field;

  return (
    <Page
      fullWidth
      permission="custom-fields"
      title="Custom Fields"
      audit={{resource: "CustomField"}}
      subtitle="Extend your catalog and clean up your data using Liquid expressions.  Custom Fields can be used as facets, sort orders, weights and more."
      resourceName={resourceName}
      disableThemes
      isChanged={isChanged}
      isLoading={!loaded}
      onBack={modalActive && (() => handleCloseModal())}
      onSave={modalActive && (() => {
        const handles = customFields.map(function(e) { return e.handle });
        if(newField && handles.includes(currentField.handle)){
          setDuplicateHandle(true);
        }
        else{
          setDuplicateHandle(false);
          handleSaveField({ onSave: () => {
            setIsChanged(false);
          } });
        }
      })}
    >
      <Layout>
        <Layout.Section>
          {hasReachedLimit && [<UpgradeBanner bundleType="custom_field"/>,<br/>]}
          <ResourcePicker
            resourceType="product"
            open={resourcePicker}
            showHidden={true}
            selectMultiple={false}
            showVariants={false}
            onSelection={(payload) => {
              setPreviewProduct(payload.selection[0]);
              if(payload.selection[0])
                setPreviewVariant(payload.selection[0].variants[0]);
              setResourcePicker(false);
            }}
            onCancel={() => {
              setResourcePicker(false);
            }}
          />
          <MultiTableForm
            loading={!loaded}
            editingForm={modalActive && modalMarkup}
            hideTable={modalActive}
            isEditing={modalActive}
            resourceName={resourceName}
            onNew={!hasReachedLimit && (() => {
              setModalActive(true);
            })}
            headings={[ "Handle", "Level" ]}
            rows={customFields && customFields.map((field, idx) => { return [
              field.handle,
              field.level
            ] })}
            bulkActions={[{
              content: "Delete custom fields",
              onAction: (ids) => deleteCustomFields(ids)
            }]}
            onRowClick={(item, idx) => {
              setCurrentField({ ...customFields[idx] });
              setCurrentHandle(customFields[idx].handle);
              setNewField(false);
              setModalActive(true);
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
