import React, {useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Icon,
  Layout,
  Tag,
  TextField,
  Form,
  Page as OldPage,
  FormLayout,
  Spinner,
  Checkbox,
  OptionList,
  ColorPicker,
  Select,
  DropZone,
  Filters,
  Modal,
  TextContainer,
} from '@shopify/polaris';
import {  MultiTableForm, MultiTable, rearrangeArray, Auth, useRedirect, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { DeleteMajor, RefreshMajor } from '@shopify/polaris-icons';
import { Page } from "../components/Page"
import { localeFormat, LocalizedTextField, LocalizedBadgeList } from "../components/Localization";
import { ResourcePickerButton } from "../components/ResourcePicker";
import { ProductTile } from "../components/Product";
import { JSONField } from "../components/Fields";
import { SpotState } from "../components/API";
import { RulesetSidebar } from "../components/RulesetSidebar";
import { ConditionTable, translateRules } from "./GlobalProperties/Ruleset";
import { SwatchSelector, SwatchText, HSVToHex, HexToHSV } from "./Swatches";
import { useParams } from "react-router-dom";

export function BannerPickerButton({ value, onSelection, onCancel, onClear, banners, swatches, label, row, selectMultiple = true, ...props}) {
  const [internalBanners, setInternalBanners] = useState(banners);
  const [internalSwatches, setInternalSwatches] = useState(swatches);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const authFetch = useSpotFetch();

  const bannerHash = internalBanners && Object.fromEntries(internalBanners.map((b) => [b.id, b]));

  useEffect(() => {
    if (banners) {
      setInternalBanners(banners);
    } else if (!internalBanners) {
      authFetch('/api/banners').then((r) => {
        setInternalBanners(r.banners);
      });
    }
  }, [banners]);
  useEffect(() => {
    if (swatches) {
      setInternalSwatches(swatches);
    } else if (!internalSwatches) {
      authFetch('/api/swatches', (r) => {
        setInternalSwatches(r.swatches);
      });
    }
  }, [swatches]);

  if (!onCancel)
    onCancel = () => { setModalOpen(false); };

  return (<><Modal
      open={modalOpen}
      onClose={() => { onCancel({}); setSelectedItems([]); }}
      title={label}
      primaryAction={{ disabled: selectedItems.length === 0, content: 'Select', onAction: () => { onSelection({ selection: Object.values(selectedItems) }); setModalOpen(false); } }}
      secondaryActions={[...(selectMultiple && onClear !== false ? [{ content: 'Clear', onAction: () => { onSelection({ selection: [] }); setSelectedItems([]);  setModalOpen(false); } }] : []), { content: 'Cancel', onAction: () => { onCancel({}); setSelectedItems([]);  setModalOpen(false); } }]}
      footer={Object.keys(selectedItems).length + (typeof(selectMultiple) == "number" ? "/" + selectMultiple : "") + " banners selected"}
    >
      {!internalBanners && <div style={{ padding: "50px" }}><Stack vertical alignment="center" distribution="center"><Spinner size="large"/></Stack></div>}
      {internalBanners && <>
        <Modal.Section>
          <MultiTable
            onRowClick={(row, idx) => {
              const isSelected = selectedItems.filter((item) => item.id == internalBanners[idx].id).length > 0;
              if (isSelected)
                setSelectedItems(selectedItems.filter((item) => item.id != internalBanners[idx].id));
              else
                setSelectedItems([...selectedItems, internalBanners[idx]]);
            }}
            headings={["", "Image", "Name"]}
            rows={internalBanners.map((item) => {
              const title = item.name;
              const isSelected = selectedItems.filter((i) => i.id == item.id).length > 0;
              const hasCheckbox = selectMultiple === true || (typeof(selectMultiple) == 'number' && Object.keys(selectedItems).length < selectMultiple) || isSelected;
              return [
                 hasCheckbox ? (<Checkbox checked={isSelected}/>) : (<span/>),
                (<BannerTile banner={item} swatches={swatches}/>),
                title
              ];
            })}
          />
        </Modal.Section></>
      }
    </Modal>  
    <Button onClick={() => { setModalOpen(true); }} {...props}>{label ? label : "Add Banner"}</Button>
  </>);
}

export function BannerTile({ banner, onClick, swatches, style = {}, children }) {
  if (banner.media && banner.media.length > 0 && banner.media[0].swatch_id && swatches) {
    const swatch = swatches.filter((s) => s.id == banner.media[0].swatch_id)[0];
    if (swatch) {
      if (swatch.image) {
        style.backgroundImage = `url(${swatch.image})`;
      } else {
        style.backgroundColor = `#${swatch.color}`;
      }
    }
  }
  return (<div key={"banner-" + (banner.id || "new")} className='spot-preview-banner' style={style} onClick={onClick}>
    {banner.title && <div className='spot-preview-banner-title' style={{color: "#"+banner.title.color}}>{banner.title.text}</div>}
    {banner.subtitle && <div className='spot-preview-banner-subtitle' style={{color: "#"+banner.subtitle.color}}>{banner.subtitle.text}</div>}
    {children}
  </div>);
}

export function BannerEditor({ banner, setBanner }) {
  const [addingType, setAddingType] = useState("swatch");
  const [showAllMetadata, setShowAllMetadata] = useState(false);
  return (<Card title="Banner" actions={[{ content: (!showAllMetadata ? "Show All Metadata" : "Hide Metadata"), onAction: () => { setShowAllMetadata(!showAllMetadata); } }]}>
    <Card.Section title="Metadata">
      <FormLayout>
        <TextField onChange={(val) => { setBanner({ ...banner, name: val }); }} value={banner.name} label="Name" placeholder="A back-end, private name for this banner."/>
        <TextField onChange={(val) => { setBanner({ ...banner, description: val }); }} value={banner.description} multiline={4} label="Description" placeholder="A back-end, private description of this banner."/>
        <LocalizedTextField onChange={(val) => { setBanner({ ...banner, title: { ...(banner.title || {}), text: val } }); }} value={banner.title && banner.title.text} label="Title" placeholder="Text to display on the banner."/>
        <ColorPicker fullWidth label="Title Color" onChange={(v) => setBanner({ ...banner, title: { ...(banner.title || {}), color: HSVToHex(v.hue, v.saturation, v.brightness) } })} color={banner.title && HexToHSV(banner.title.color)} />
        <LocalizedTextField onChange={(val) => { setBanner({ ...banner, subtitle: { ...(banner.subtitle || {}), text: val } }); }}  value={banner.subtitle && banner.subtitle.text} label="Subtitle" placeholder="Text to display on the banner."/>
        <ColorPicker fullWidth label="Subtitle Color" onChange={(v) => setBanner({ ...banner, subtitle: { ...(banner.subtitle || {}), color: HSVToHex(v.hue, v.saturation, v.brightness)} })} color={banner.subtitle && HexToHSV(banner.subtitle.color)} />
        <LocalizedTextField onChange={(val) => { setBanner({ ...banner, link: val }); }}  value={banner.link} label="Link" placeholder="The address to link to, if this banner is clicked."/>
        <TextField label="Class" onChange={(val) => { setBanner({ ...banner, ["class"]: val }); }} value={banner["class"]} placeholder="A comma-separated list of CSS classes to apply to this banner."/>
        {showAllMetadata && <JSONField  label="Extra" multiline={4} placeholder="null"  onChange={(val) => { setBanner({ ...banner, extra: val }) }} value={banner.extra}/>}
      </FormLayout>
    </Card.Section>
    <Card.Section title="Media">
      <MultiTable
        headings={["Type", "Handle", "Details", ""]}
        onDragDrop={(source, destination) => {
          setBanner({ ...banner, media: rearrangeArray(banner.media, source, destination) });
        }}
        rows={banner.media.map((m, idx1) => [m.type, 
          <TextField placeholder="optional-handle" value={m.handle} onChange={(val) => { setBanner({ ...banner, media: banner.media.map((n, idx2) => idx1 == idx2 ? { ...m, handle: val } : n) }) }} />, 
          <SwatchSelector onChange={(value) => { setBanner({ ...banner, media: banner.media.map((n, idx2) => idx1 == idx2 ? { ...m, swatch_id: value } : n) }) }} value={m.swatch_id}/>, 
          (<Button onClick={() => {  setBanner({ media: banner.media.filter((m, idx2) => idx2 != idx1) }); }}><Icon source={DeleteMajor} color="base" /></Button>)
        ])}
      />
      <Button primary fullWidth onClick={() => { setBanner({ ...banner, media: [...banner.media, { type: "swatch", swatch_id: null }] }); }}>+ Add Media</Button>
    </Card.Section>
  </Card>);
}

export function Banners() {
  const redirect = useRedirect();
  const [banners, setBanners] = useState(null);
  const [swatches, setSwatches] = useState(null);
  const [banner, setBanner] = useState(null);

  const authFetch = useSpotFetch();

  const { bannerId } = useParams();
  
  useEffect(() => {
    if (!swatches)
      authFetch("/api/swatches").then((r) => setSwatches(r.swatches));
  }, [swatches]);
  useEffect(() => {
    if (!banners)
      authFetch("/api/banners").then((r) => setBanners(r.banners));
  }, [banners]);

  useEffect(() => {
    if (banners) {
      if (bannerId && !banner) {
        if (bannerId == "new") {
          setBanner({ media: [], title: { color: "ffffff" }, subtitle: { color: "aaaaaa" } });
        } else {
          setBanner(banners.filter((b) => b.id == bannerId)[0]);
        }
      } else if (!bannerId && banner) {
        setBanner(null);
      }
    }
  }, [bannerId, banners]);

  if (banner) {
    return (<Page 
      title="Edit Banner"
      onBack={() => { redirect("/globals/banners"); }}
      onSave={() => {
        authFetch("/api/banners", { json: banner }).then((r) => {
          if (r.banner.id)
            setBanners(banners.map((b) => b.id == banner.id ? r.banner : b));
          else
            setBanners([...banners, r.banner]);
          setBanner(null);
          redirect("/globals/banners");
        });
      }}
      isChanged={banners && banners.filter((b) => b == banner).length == 0}
    >
      <Layout>
        <Layout.Section>
          <BannerEditor banner={banner} setBanner={setBanner}/>
        </Layout.Section>
        <Layout.Section secondary>
          <Card sectioned title="Banner Preview">
            <BannerTile banner={banner} swatches={swatches}/>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>);
  }

  return (<Page title="Banners" isFullyLoading={!banners}>
    <div style={{ display: "grid", gridTemplateColumns: "33% 33% 33%", columnGap: "10px", rowGap: "10px"}} >
      {banners && banners.map((b, idx) => (<BannerTile banner={b} swatches={swatches} style={{ cursor: "pointer" }} onClick={() => { redirect("/globals/banners/" + b.id); }}>
        <Button onClick={(e) => {
          e.preventDefault();
          authFetch("/api/banners/" + b.id, { method: "DELETE" }).then((r) => {
            setBanners(banners.filter((b, idx2) => idx != idx2));
          });
        }}><Icon source={DeleteMajor} color="base" /></Button>
      </BannerTile>))}
      <Card sectioned title="New Global Banner">
        <Stack style={{ minHeight: "200px" }} alignment="center" distribution="fill" spacing="extraLoose">
          <Button fullWidth onClick={() => {
            redirect("/globals/banners/new");
          }}>Add new Banner</Button>
        </Stack>
      </Card>
    </div>
  </Page>);
}

