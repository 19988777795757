import React, {useEffect, useState, useRef} from 'react';
import { ResourcePickerButton } from './ResourcePicker';
import {
  TextField,
	Select,
	Icon,
	Button,
	ButtonGroup
} from '@shopify/polaris';
import { Stack } from "admin-frontend";
import { SearchMinor, CircleCancelMinor } from '@shopify/polaris-icons';

export const productFields = [{
	"name": "Handle",
	"handle": "handle",
	"level": "product",
	"type": "string",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Title",
	"handle": "title",
	"level": "product",
	"type": "string",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Custom Product Type",
	"handle": "product_type",
	"level": "product",
	"type": "string",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Category",
	"handle": "category",
	"level": "product",
	"type": "string",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Created",
	"handle": "created",
	"level": "product",
	"type": "numeric",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Vendor",
	"handle": "vendor",
	"level": "product",
	"type": "string",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Manual Product Weight",
	"handle": "priority",
	"level": "product",
	"type": "numeric",
	"specifiers": [],
	"sortable": true,
	"public": false
}, {
	"name": "Sales",
	"handle": "sales",
	"level": "product",
	"type": "numeric",
	"specifiers": [],
	"sortable": true,
	"public": false
},{
	"name": "Inventory Quantity",
	"handle": "inventory_quantity",
	"level": "variant",
	"type": "numeric",
	"nullable": true,
	"specifiers": [],
	"sortable": false,
	"public": true
},{
	"name": "Online Inventory Quantity",
	"handle": "inventory_quantity_online",
	"level": "variant",
	"type": "numeric",
	"nullable": true,
	"specifiers": [],
	"sortable": false,
	"public": true
}, {
	"name": "Available",
	"handle": "available",
	"level": "variant",
	"type": "bool",
	"specifiers": [],
	"sortable": false,
	"public": true
}, {
	"name": "Tag",
	"handle": "tag",
	"level": "product",
	"type": "list",
	"specifiers": [],
	"sortable": false,
	"public": true
}, {
	"name": "Option 1",
	"handle": "option1",
	"level": "variant",
	"type": "both",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Option 2",
	"handle": "option2",
	"level": "variant",
	"type": "both",
	"specifiers": [],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Option 3",
	"handle": "option3",
	"level": "variant",
	"type": "both",
	"specifiers": [],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Option",
	"handle": "option",
	"level": "variant",
	"type": "both",
	"specifiers": ["name"],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "SKU",
	"handle": "sku",
	"level": "variant",
	"type": "both",
	"specifiers": [],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Barcode",
	"handle": "barcode",
	"level": "variant",
	"type": "both",
	"specifiers": [],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Product Metafield",
	"handle": "product-metafield",
	"level": "product",
	"type": "both",
	"specifiers": ["namespace", "key"],
	"sortable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Product Custom Field",
	"handle": "product-custom-field",
	"level": "product",
	"type": "both",
	"specifiers": ["handle"],
	"sortable": true,
	"public": true
}, {
	"name": "Variant Metafield",
	"handle": "variant-metafield",
	"level": "variant",
	"type": "both",
	"specifiers": ["namespace", "key"],
	"sortable": true,
	"nullable": true,
	"mandatory": false,
	"public": true
}, {
	"name": "Variant Custom Field",
	"handle": "variant-custom-field",
	"level": "variant",
	"type": "both",
	"nullable": true,
	"specifiers": ["handle"],
	"sortable": true,
	"public": true
}, {
	"name": "Price",
	"handle": "price",
	"level": "variant",
	"type": "numeric",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Collection",
	"handle": "collection",
	"level": "product",
	"type": "collection",
	"specifiers": [],
	"sortable": false,
	"public": false
}, {
	"name": "Compare at Price",
	"handle": "compare_at_price",
	"level": "variant",
	"type": "numeric",
	"nullable": true,
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Published Date",
	"handle": "published_at",
	"level": "product",
	"type": "date",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Created Date",
	"handle": "created_at",
	"level": "product",
	"type": "date",
	"specifiers": [],
	"sortable": true,
	"public": true
}, {
	"name": "Product",
	"handle": "id",
	"level": "product",
	"type": "product",
	"specifiers": [],
	"public": true
}, {
	"name": "Variant",
	"handle": "variant_id",
	"level": "variant",
	"type": "variant",
	"specifiers": [],
	"public": true
}, {
	"name": "Inventory Level",
	"handle": "inventory_level",
	"level": "variant",
	"type": "numeric",
	"specifiers": ["location_id"],
	"sortable": true,
	"public": true
}, {
	"name": "Split Product",
	"handle": "split_id",
	"level": "product",
	"type": "product",
	"specifiers": [],
	"public": true
}];

export function titleCase(title) {
	if (title === null || title === undefined)
		return title;
	return title.split(/[\s_]+/).map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(" ");
}

/* Resuable dialog, specifies a field to be specified. */
export function FieldSpecifier({ fields = productFields, label, disabled, publicOnly = false, field = null, sortable = null, level = "both", specifiersOnly = false, chooseFromList = false, chooseValue = false, type = "both", idx = 0, extraFields = [], split = null, onChange }) {
	let validFields = fields.filter((e) => level === "both"|| e.level === "both" || e.level === level).filter((e) => type === "both" || e.type === "both" || e.type === type || (typeof(type) == "object" && type.includes(e.type)))
		.filter((e) => sortable === null || e.sortable === sortable).concat(extraFields).filter((e) => !specifiersOnly || (e.type === "list" && chooseFromList) || e.specifiers.length > 0).filter((e) => !publicOnly || e.public);

	const currentField = validFields.filter((e) => field && e.handle === field[0])[0];

	useEffect(() => {
		if (currentField) {
			const specifierLength = currentField.specifiers.length + ((currentField.type === "list" && chooseFromList) || chooseValue ? 1 : 0);
			if (!field || field.length !== specifierLength + 1) {
				let array = [currentField.handle];
				for (var i = 0; i < specifierLength; ++i)
					array.push(null);
				onChange(array)
			}
		} else {
			onChange([validFields[0].handle]);
		}
	}, [field, onChange, validFields, chooseFromList, chooseValue, currentField]);
	if (!field)
		return <></>;
	if (!currentField)
		throw new TypeError("Invalid field selected: " + field[0]);


	const selectedButton = (<Select disabled={disabled} key={"handle-" + idx} label={label} value={currentField.handle} onChange={(handle) => {
		const targetField = validFields.filter((e) => e.handle === handle)[0];
		const specifierLength = targetField.specifiers.length + (targetField.type === "list" && chooseFromList ? 1 : 0);
		onChange([handle].concat(field.slice(1, Math.min(field.length, specifierLength+1)).concat(Array(Math.max(0, specifierLength - field.length + 1)).fill(null))));
	}} options={validFields.map((e) => { return { label: e.name, value: e.handle }; })}/>);

	if (field.length === 1)
		return selectedButton;
	if (currentField.type === "product" || currentField.type === "variant" || currentField.type === "collection") {
		return (<Stack alignment="baseline" distribution="fill">
			{selectedButton}
			<Stack.Item style={{flexShrink: 1}}>
				<ResourcePickerButton
					resourceType={currentField.type.toLowerCase()}
					showHidden={true}
					selectMultiple={chooseFromList}
					split={split}
					disabled={disabled}
					initialSelectionIds={field[1]}
					onSelection={(payload) => {
						onChange(field.map((e, idx) => idx === 1 ? payload.selection.map((p) => p.id) : e));
					}}
				/>
			</Stack.Item>
		</Stack>);
	}
	return <Stack>
		{selectedButton}
		{field.slice(1).map((e1, idx1) => {
			return (<TextField disabled={disabled} placeholder={currentField.specifiers[idx1]} label={label && titleCase(currentField.specifiers[idx1])} key={"spec-" + idx + "-" + idx1} value={e1} onChange={(value) => {
				onChange(field.map((e2, idx2) => idx1+1 === idx2 ? value : e2));
			}}/>);
		})}
	</Stack>
}

export function convertToFrontend(properties) {
	return properties != null && typeof(properties) == "object" ? [Object.keys(properties)[0], ...convertToFrontend(Object.values(properties)[0])] : [properties];
}

export function convertFromFrontend(properties) {
	if (properties.length === 1)
		return properties[0];
	var object = {};
	var current = object;
	properties.slice(0, -2).forEach((p, idx) => { current[p] = {}; current = current[p]; });
	current[properties[properties.length - 2]] = properties[properties.length - 1];
	return object;
}

export function getOperators(field) {
	let quantitativeOperators = ["equal to", "not equal to", "less than", "greater than", "greater than or equal to", "less than or equal to"];
	let qualitativeOperators = ["starts with", "ends with", "contains", "does not contain"];
	const exactOperators = ["equal to", "not equal to"];
	const listOperators = ["equal to", "not equal to", "starts with"];
	const type = typeof(field) === "object" ? field.type : field;
	if (typeof(field) === "object" && field.mandatory === false) {
		quantitativeOperators = quantitativeOperators.concat(["exists", "doesn't exist"]);
		qualitativeOperators = qualitativeOperators.concat(["exists", "doesn't exist"]);
	}
	if (typeof(field) === "object" && field.nullable === true) {
		quantitativeOperators = quantitativeOperators.concat(["is not empty", "is empty"]);
		qualitativeOperators = qualitativeOperators.concat(["is not empty", "is empty"]);
	}
	if (type === "numeric")
		return quantitativeOperators;
	else if (type === "string")
		return exactOperators.concat(qualitativeOperators);
	else if (type === "list")
		return listOperators;
	else if (type === "collection" || type === "product" || type === "variant")
		return exactOperators;
	else if (type === "bool")
		return ["is true", "is false"];
	return Object.keys(Object.fromEntries([...quantitativeOperators, ...qualitativeOperators].map((e) => [e, 1])));
}

export function CachedTextField({ prefix, postfix, value, label, idle, onChange, multiline = 1, backdrop = true, disabled, ...props }) {
	const [originalValue, setOriginalValue] = useState(value);
	const [internalValue, setInternalValue] = useState(value);
	const idleTimeout = useRef(null);
	useEffect(() => {
		if (value != internalValue || value != originalValue)
			onChange(value);
		setInternalValue(value);
		setOriginalValue(value);
	}, [value]);
	const onChangeReset = (type, event, value) => {
		const ret = onChange(value != null ? value : internalValue, type, event);
		if (ret != null && !ret)
			setInternalValue(originalValue);
		else
			setOriginalValue(value != null ? value : internalValue);
	};
	const properties = {
		...props,
		className: `Polaris-TextField__Input ${props.className || ''}`,
		onFocus: (e) => {
			if (e.target.type != "number")
				e.target.setSelectionRange(0, e.target.value.length);
		},
		onChange: (e) => {
			setInternalValue(e.target.value);
			if (idle) {
				if (idleTimeout.current)
					clearTimeout(idleTimeout.current);
				idleTimeout.current = setTimeout(() => {
					onChangeReset("idle", e, e.target.value);
				}, idle*1000);
			}
		},
		onKeyPress: (e) => {
			if (e.nativeEvent.keyCode == 13) { // Fucking react sets this to 0 on their SyntheticEvent.
				onChangeReset("enter", e);
			}
		},
		onKeyDown: (e) => {
			if (e.nativeEvent.keyCode == 9) {
				onChangeReset(e.nativeEvent.shiftKey ? "shift+tab" : "tab", e);
			}
		},
		onBlur: (e) => {
			onChangeReset("blur", e);
		}
	};
	return (<div>
		{label && <div className='Polaris-Labelled__LabelWrapper'>
			<div className="Polaris-Label">
				<label className="Polaris-Label__Text">{label}</label>
			</div>
		</div>}
		<div className='Polaris-Connected'>
			<div className='Polaris-Connected__Item Polaris-Connected__Item--primary'>
				<div className='Polaris-TextField'>
					{prefix && <div className="Polaris-TextField__Prefix">
						{prefix}
					</div>}
					{!multiline || multiline == 1 && <input type='text' value={internalValue} disabled={disabled} {...properties}/>}
					{multiline && multiline > 1 && <textarea type='text' disabled={disabled} rows={multiline} {...properties}>{internalValue}</textarea>}
					{postfix && <div className="Polaris-TextField__Postfix">
						{postfix}
					</div>}
				</div>
				{backdrop && <div className='Polaris-TextField__Backdrop'></div>}
			</div>
		</div>
		</div>
		);
}

export function SearchField({ value, onChange, ...props }) {
	return <CachedTextField
		idle={0.2}
		prefix={<Icon source={SearchMinor} />}
		postfix={value != "" && value != null && <div className='Polaris-TextField__ClearButton' style={{ marginTop: '4px' }}><Button plain onClick={() => { onChange(""); }}><Icon source={CircleCancelMinor} /></Button></div>}
		value={value}
		onChange={onChange}
		{...props}
	/>
}

export function JSONField({ value, onChange, ...props }) {
	const [internalValue, setInternalValue] = useState((value == null ? "" : (typeof(value) == 'object' ? JSON.stringify(value) : value)));
	useEffect(() => {
		setInternalValue((value == null ? "" : (typeof(value) == 'object' ? JSON.stringify(value) : value)));
	}, [value]);
	let jsonError = null;
	let parsedJson = null;
	if (!/^\s*$/.test(internalValue)) {
		try {
			parsedJson = JSON.parse(internalValue);
		} catch (e) {
			jsonError = e.toString();
		}
	}
	return <TextField error={jsonError} value={internalValue} onBlur={(value) => { 
		if (parsedJson) {
			onChange(parsedJson);
		} else if (/^\s*$/.test(internalValue)) {
			onChange(null);
		}
	}} onChange={setInternalValue} {...props}/>
}
