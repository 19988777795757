import { useCallback } from 'react';
import { useRedirectFetch, useCreateToast, useProfile } from "admin-frontend";
import { useStatus } from "./components/StatusContext";

export function useSpotFetch() {
  const authFetch = useRedirectFetch();
  const createToast = useCreateToast();
  const [status, refreshStatus] = useStatus();
  return useCallback(
    (url, options = {}, ...rest) =>
      authFetch(url, options, ...rest)
        .then((r) => {
          if (options.json)
            refreshStatus();
          return r
        }, (r) => {
          if (r && r.toString().indexOf("Lacks read permission") == -1)
            createToast({ error: true, content: r.toString() });
          return Promise.reject(r);
        }),
    [authFetch, createToast]
  );
}

