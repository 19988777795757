import React, { useEffect, useState } from 'react';
import {
  RangeSlider,
  TextField,
} from '@shopify/polaris';
import { Stack } from "admin-frontend";


export function EntryRangeSlider({ value, onChange, ...others }) {
  const [text, setText] = useState(value + "");
  useEffect(() => {
    setText(value + "");
  }, [value])
  return (<Stack alignment="center"><Stack.Item fill><RangeSlider value={value} onChange={onChange} {...others}/></Stack.Item><Stack.Item><div  style={{ maxWidth: "64px" }}><TextField onChange={(val) => {
    setText(val);
    if (val != "") {
      if (others.min != null || others.max != null)
        onChange(Math.min(Math.max(parseFloat(val), others.min), others.max) + "");
      else
        onChange(val);
    }
  }} value={text}/></div></Stack.Item></Stack>)
}

