import React, {useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Icon,
  Layout,
  Tag,
  TextField,
  Form,
  Spinner,
  Select,
  Label,
  Banner,
  Modal,
  ColorPicker,
  DropZone,
  Heading,
  TextContainer,
} from '@shopify/polaris';
import { AuthorizationBanner, Stack, useRedirect } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { DeleteMajor, RefreshMajor, PlusMinor, CircleDisabledMajor } from '@shopify/polaris-icons';
import { Page } from "../components/Page"

export function HSVToHex(h, s, v) {
  var r, g, b, i, f, p, q, t;
  h = h / 360;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      case 5: r = v; g = p; b = q; break;
      default: break;
  }
  r = Math.round(r * 255).toString(16)
  g = Math.round(g * 255).toString(16)
  b = Math.round(b * 255).toString(16);
  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;
  return r + g + b;
}

export function HexToHSV(code) {
  var r = parseInt(code.substr(0,2) || "00", 16);
  var g = parseInt(code.substr(2,2) || "00", 16);
  var b = parseInt(code.substr(4,2) || "00", 16);
  let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc;
  rabs = r / 255;
  gabs = g / 255;
  babs = b / 255;
  v = Math.max(rabs, gabs, babs);
  diff = v - Math.min(rabs, gabs, babs);
  diffc = c => (v - c) / 6 / diff + 1 / 2;
  if (diff === 0) {
      h = s = 0;
  } else {
      s = diff / v;
      rr = diffc(rabs);
      gg = diffc(gabs);
      bb = diffc(babs);

      if (rabs === v) {
          h = bb - gg;
      } else if (gabs === v) {
          h = (1 / 3) + rr - bb;
      } else if (babs === v) {
          h = (2 / 3) + gg - rr;
      }
      if (h < 0) {
          h += 1;
      }else if (h > 1) {
          h -= 1;
      }
  }
  var result = {
      hue: Math.round(h * 360),
      saturation: s,
      brightness: v
  };
  return result;
}

export function getSwatchCSS(swatch) {
  if (swatch.color)
    return { backgroundColor: "#" + swatch.color };
  return { backgroundImage: "url('" + swatch.image + "')" };
}

export function SwatchText({ swatch, children }) {
  if (!swatch)
    return children;
  return (<Stack wrap={false} alignment='flexStart'>
    <span style={swatch && getSwatchCSS(swatch)} className='swatch-preview-small'></span>
    <span className='swatch-text'>{children}</span>
  </Stack>);
}

export function SwatchSelector({ value, onChange, label, swatches, setSwatches, ...props }) {
  const [localSwatches, localSetSwatches] = useState(null);
  const _swatches = swatches || localSwatches;
  const _setSwatches = setSwatches || localSetSwatches;
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const redirect = useRedirect();

  const authFetch = useSpotFetch();
  useEffect(() => {
    if ((open || value) && !_swatches) {
      authFetch("/api/swatches", { }).then((r) => {
        _setSwatches(r.swatches);
        setIsLoading(false);
      });
    }
  }, [open, authFetch, _swatches, _setSwatches]);
  const swatch = _swatches && _swatches.filter((s) => s.id == value)[0];

  const openButton = (
    <Button key={"openSwatch"} onClick={() => { setOpen(true); }} loading={isLoading} {...props}>
      <SwatchText swatch={swatch}>{!value ? "No Swatch" : (swatch ? swatch.name[0] : "Unnamed Swatch")}</SwatchText>
    </Button>
  );

  return (<>
    <Modal key={"modalSwatch"}
      open={open}
      onClose={() => { setOpen(false); }}
      title={"Select a Swatch"}
    >
      <div style={{ padding: "3rem" }}>
        <Button fullWidth onClick={() => { onChange(null); setOpen(false); }}>
          <Stack alignment='center'><Icon source={CircleDisabledMajor}/><span>No Swatch</span></Stack>
        </Button>
        <Stack distribution="spaceBetween" style={{ padding: "1rem 0" }}>
          {_swatches && _swatches.map((swatch) => {
            return (<Button onClick={() => { onChange(swatch.id); setOpen(false); }}><Stack vertical>
              <div>
                <div className='swatch-preview-large' style={swatch && getSwatchCSS(swatch)} key={"swatch-" +swatch.id}></div>
              </div>
              <div style={{ textAlign: "center" }}>{swatch.name[0] || "Unnamed"}</div>
            </Stack></Button>);
          })}
        </Stack>
        <Button fullWidth onClick={() => { redirect('/globals/swatches'); }}>
          <Stack alignment='center'><Icon source={PlusMinor}/><span>Add Swatch</span></Stack>
        </Button>
      </div>
    </Modal>

    {label && <Label>{label}</Label>}
    {openButton}
  </>)
}

export function SwatchPicker({ setSwatch, swatch }) {
  const [temporaryName, setTemporaryName] = useState("");
  const [error, setError] = useState(null);
  const loading = swatch.status == 2 || swatch.status == 1 || swatch.loading;
  return (<>
    {loading && <Stack alignment="center" distribution="center"><div style={{textAlign: "center"}}>Uploading to Shopify...<br/><br/><Spinner size="large"/></div></Stack>}
    {!loading && (swatch.color ? (
      <Stack vertical>
        <ColorPicker fullWidth onChange={(v) => setSwatch({ ...swatch, updated: true, color: HSVToHex(v.hue, v.saturation, v.brightness)})} color={HexToHSV(swatch.color)} />
        <TextField onChange={(v) => {
          if (/^[a-f0-9]*$/.test(v))
            setSwatch({ ...swatch, color: v });
        }} prefix="#" value={swatch.color}/>
      </Stack>
    ) : (
      <DropZone allowMultiple={false} accept="image/*" type="image" onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
        setSwatch({ ...swatch, file: acceptedFiles[0], updated: true, error: null });
      } }>
        {swatch.error ? ((<Banner
          title="There was an error uploading your image."
          status="critical"
        >
          <p>
            Error uploading your image: {swatch.error}
          </p>
        </Banner>)) : (swatch.image ? <div style={{textAlign: "center"}}><img style={{ maxHeight: "204px", maxWidth: "100%"}} src={swatch.image} alt={"Swatch"}/></div> : <DropZone.FileUpload />)}
      </DropZone>
    ))}
    {!loading && <Stack vertical>
      <Stack>
      {swatch.name.map((name) => (<Tag key={name} onRemotve={() => {
        setSwatch({ ...swatch, name: swatch.name.filter((n) => n !== name), updated: true });
      }}>{name}</Tag>))}
      </Stack>
      <Form onSubmit={() => {
        setSwatch({ ...swatch, name: [...swatch.name, temporaryName], updated: true });
      }}>
        <TextField error={error && ("Duplicate name value: " + error)} value={temporaryName || ""} onChange={(v) => {
          setTemporaryName(v);
          setSwatch({ ...swatch, updated: true });
        }} placeholder="Color name, press enter when done."/>
      </Form>
    </Stack>}
  </>);
}

export function SwatchCard({ setSwatch, swatch }) {
  return (<Card
    title={swatch.color ? (<SwatchText swatch={swatch}><Heading>Solid Swatch</Heading></SwatchText>) : "Image Swatch"}
    actions={[
      {content: <Icon source={RefreshMajor} />, onAction: () => {
        if (swatch.color) {
          setSwatch({ ...swatch, color: undefined, updated: true });
        } else {
          setSwatch({ ...swatch, color: "ff0000", updated: true });
        }
      }},
      {content: <Icon source={DeleteMajor} />, onAction: () => { setSwatch({ ...swatch, updated: true, deleted: true }); }}
    ]} sectioned>
      <SwatchPicker swatch={swatch} setSwatch={setSwatch}/>
  </Card>);
}

export function Swatches() {
  const [isLoading, setIsLoading] = useState(true);
  let [swatches, setSwatches] = useState(null);
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [defaultNames, setDefaultNames] = useState(null);
  const [errors, setErrors] = useState({});

  const authFetch = useSpotFetch();

  useEffect(() => {
    authFetch("/api/swatches", { }).then((r) => {
      setSwatches(r.swatches);
      setIsLoading(false);
    });
  }, [authFetch, setSwatches]);

  useEffect(() => {
    if (defaultNames == null) {
      setDefaultNames({});
      authFetch("/api/facets", { }).then((r) => {
        const [colorFacet] = r.facets.filter((f) => /colou?r/i.test(f.name));
        if (colorFacet) {
          authFetch("/api/facets/" + colorFacet.id).then((r) => {
            var hash = {};
            const f = r.facet;
            if (f.values) {
              f.values.forEach((fv) => {
                if (fv.value) {
                  if (typeof(fv.value) == "object") {
                    fv.value.forEach((v) => {
                      hash[v.toLowerCase().replace(/[^a-z0-9]/g, "")] = fv.name;
                    });
                  } else {
                    hash[fv.value.toLowerCase().replace(/[^a-z0-9]/g, "")] = fv.name;
                  }
                }
              });
            }
            setDefaultNames(hash);
          });
        }
      });
    }
  // eslint-disable-next-line
  }, [authFetch, setDefaultNames]);

  const getDefaultNames = useCallback((file) => {
    const transformed = file.name.toLowerCase().replace(/[^a-z0-9]/g, "");
    let names = [];
    if (defaultNames) {
      Object.keys(defaultNames).forEach((v) => {
        if (transformed.indexOf(v) !== -1)
          names.push(defaultNames[v]);
      });
    }
    return names;
  // eslint-disable-next-line
  }, [defaultNames]);

  const updateSwatches = useCallback((swatches) => {
    if (swatches && swatches.filter((s) => s.status === 1 || s.status === 2).length > 0) {
      return authFetch("/api/swatches", { }).then((r) => {
        var hash = Object.fromEntries(r.swatches.map((s) => [s.id, s]));
        setSwatches([...swatches.map((s) => s.id && hash[s.id] ? { ...s, image: hash[s.id].image, status: hash[s.id].status } : s)]);
      });
    }
    return null;
  // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (updateTimeout)
      clearTimeout(updateTimeout);
    var cycle;
    cycle = function() {
      setUpdateTimeout(setTimeout(() => {
        updateSwatches(swatches);
        cycle();
      }, 1000));
    };
    cycle();
	// eslint-disable-next-line
  }, [swatches, updateSwatches]);

  const checkSwatchesApplyError = useCallback((swatches) => {
    var names = {};
    var errorIdx = null;
    swatches.filter((s) => !s.deleted).forEach((s, idx) => {
      s.name.forEach((name) => {
        if (names[name] !== undefined) {
          errorIdx = idx;
          setErrors({ [errorIdx]: name });
        }
        names[name] = idx;
      });
    })
    return errorIdx;
  });

  return (<Page
    title="Swatches"
    permission="setup"
    audit={{resource: "Swatch"}}
    subtitle="Optionally specify colors or images to use as swatches in Spot facets, and breadcrumbs."
    disableThemes
    onSave={swatches && swatches.filter((e) => e.updated).length > 0 && (() => {
      let totalRequests = 0;
      if (checkSwatchesApplyError(swatches) === null) {
        setIsLoading(true);
        setErrors({});
        swatches.filter((e) => e.updated).forEach((s) => {
          // New file upload.
          if (!s.deleted) {
            const formData  = new FormData();
            if (s.file)
              formData.append("file", s.file);
            if (s.name)
              formData.append("name", JSON.stringify(s.name));
            if (s.color)
              formData.append("color", s.color);
            setSwatches(swatches.map((e) => e === s ? { ...s, loading: true } : e));
            ++totalRequests;
            authFetch("/api/swatches" + (s.id ? "/" + s.id : ""), { method: "POST", body: formData }).then((r) => {
              swatches = swatches.map((e) => e === s ? r.swatch : e);  // We do it like this, because every time this callback runs, it uses the original swatches if we don't assign.
              setSwatches(swatches);
              if (--totalRequests === 0)
                setIsLoading(false);
            });
          } else {
            ++totalRequests;
            authFetch("/api/swatches/" + s.id, { method: "DELETE" }).then((r) => {
              swatches = swatches.filter((e) => e !== s); // We do it like this, because every time this callback runs, it uses the original swatches if we don't assign.
              setSwatches(swatches);
              if (--totalRequests === 0)
                setIsLoading(false);
            });
          }
        })
      }
    })}
    isFullyLoading={isLoading}
    resourceName={{
      singular: "swatch",
      plural: "swatches"
    }}
  >
    <Layout>
      <Layout.Section>
        <AuthorizationBanner style={{marginBottom: "12px"}} scopes={["write_files"]} />
        <Card sectioned>
          <TextContainer>
            <p>Swatches are optional, and represent images or colors that can be applied to your facets/breadcrumbs. Image file names will automatically be interpreted as names (red.jpg will be Red). Specify each translated facet value (Red, Rouge). For grouped facet values, enter only the facet's display name (Enter Red for a group containing "maroon", "wine" and "burgundy").</p>
            <p>If you'd like to use these in your page in a custom manner, you can use <code>swatch-&lt;color_name&gt;</code> as a class for an element, and it will apply the facet correctly as a <code>background-color</code> or as a <code>background-image</code>, if you are using <code>spot.liquid</code>.</p>
            <p>This facet information can be accessed directly on the front-end by the metafield `shop.metafields.esafilters.swatches`, which is a json field that takes the following form: </p>
            <p><code>{'[{"names": ["red","maroon"], "image": "https://cdn.shopify.com/img.png"},{"names":["green"], "color": "00FF00"},...]'}</code></p>
          </TextContainer>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <div style={{ display: "grid", gridTemplateColumns: "33% 33% 33%", columnGap: "10px", rowGap: "10px"}} >
            {swatches && swatches.filter((s) => !s.deleted).map((s, idx) => {
              return (<div key={"swatch-" + s.id}>
                <SwatchCard swatch={s} setSwatch={(swatch) => { setSwatches(swatches.map((sw, idx2) => idx2 == idx ? swatch : sw).filter((sw) => !sw.deleted || sw.id)) }}/>
              </div>);
            })}
            <Card sectioned title="New Swatch">
              <Stack vertical distribution="fill" spacing="extraLoose">
                <DropZone accept="image/*" type="image" onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
                  setSwatches([...swatches, ...acceptedFiles.map((file) => { return { file: file, image: URL.createObjectURL(file), name: getDefaultNames(file), updated: true } })]);
                } }>
                  <DropZone.FileUpload />
                </DropZone>
                <div style={{textAlign: "center"}}>or</div>
                <Button fullWidth onClick={() => {
                  setSwatches([...swatches, { color: 'ff0000', name: [], updated: true }])
                }}>Add new Solid Swatch</Button>
              </Stack>
            </Card>
          </div>
      </Layout.Section>
    </Layout>
  </Page>);
}

