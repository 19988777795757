import React, {useState, useEffect} from 'react';
import {
  Layout,
  Button,
  ButtonGroup,
  TextField,
  Badge,
  Tooltip,
  Card,
  Icon,
  OptionList
} from '@shopify/polaris';
import { MultiTable, Auth, Stack } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { productFields, FieldSpecifier } from "../../components/Fields"
import { Page } from "../../components/Page"
import { UpgradeBanner } from "../../components/UpgradeBanner";
import { CircleInformationMajor } from '@shopify/polaris-icons';

export function ProductSplits() {
  const [error, setError] = useState({});
  const [productSplits, setProductSplits] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [productSplitsLoading, setProductSplitsLoading] = useState(true);
  const [productSplitsLoaded, setProductSplitsLoaded] = useState(false);
  const [editingSplitId, setEditingSplitId] = useState(null);
  const [editingSplitHandle, setEditingSplitHandle] = useState([]);
  const [editingSplitProperties, setEditingSplitProperties] = useState([]);
  const [editingSplitLocales, setEditingSplitLocales] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [profile] = Auth.useProfile();

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!productSplitsLoaded) {
      authFetch("/api/global/product_splits")
        .then((r) => {
          setProductSplits(Object.keys(r.product_splits).sort().map((e) => r.product_splits[e]));
          setUpdatedAt(r.updated_at);
          setProductSplitsLoaded(true);
          setProductSplitsLoading(false);
      });
    }
  }, [productSplitsLoaded, authFetch]);

  function saveSplits(productSplits) {
    let productSplitHash = {};
    productSplits.forEach((e) => productSplitHash[e.handle] = e);
    setProductSplitsLoading(true);
    authFetch("/api/global/product_splits", { json: { product_splits: productSplitHash, updated_at: updatedAt } })
      .then((r) => {
        setUpdatedAt(r.updated_at);
        setProductSplits(Object.keys(r.product_splits).sort().map((e) => r.product_splits[e]));
        setProductSplitsLoading(false);
      });
  }

  function convertToFrontend(properties) {
    return properties.map((e) => {
      if (e.type === "option" || e.type === "variant-custom-field")
        return [e.type, e.option_name];
      else if (e.type === "variant-metafield")
        return [e.type, e.namespace, e.key];
      return [e.type];
    });
  }

  function convertFromFrontend(properties) {
    return properties.map((s) => {
      const field = productFields.filter((f) => f.handle === s[0])[0];
      let property = {
        type: field.handle
      };
      if (field.handle === "option" || field.handle === "variant-custom-field")
        property["option_name"] = s[1];
      else if (field.handle === "variant-metafield") {
        property["namespace"] = s[1];
        property["key"] = s[2];
      }
      return property;
    });
  }

  let isEditing = editingSplitProperties.length !== 0;
  const canUseProductSplitAndMerge = profile?.shop?.active_subscription?.features?.split_and_merge?.value !== false;

  return (
  <Page
    title="Product Splits"
    permission="merges-and-splits"
    audit={{resource: "ProductSplit"}}
    subtitle="Display separate tiles for each catalog attribute you choose to split."
    resourceName={{ singular: "product split", plural: "product splits" }}
    isChanged={isChanged}
    disableThemes
    localization
    onBack={isEditing && (() => { setEditingSplitProperties([]) })}
    onSave={isEditing && (() => {
      setError({});
      if (!editingSplitHandle || /^\s*$/.test(editingSplitHandle)) {
        setError({ ...error, handle: "Requires a handle." });
        return;
      }
      if (!/^[a-z0-9\-_]+$/.test(editingSplitHandle)) {
        setError({ ...error, handle: "Handle must only constist of lower-case letters, numbers, hyphens or underscores." });
        return;
      }
      saveSplits([...productSplits.filter((e) => !editingSplitId || editingSplitId !== e.handle),  { handle: editingSplitHandle, properties: convertFromFrontend(editingSplitProperties), locales: editingSplitLocales }]);
      setEditingSplitId(null);
      setEditingSplitHandle('');
      setEditingSplitProperties([]);
      setIsChanged(false);
    })}
  >
    {(currentTheme, currentLocale) => {
      if (isEditing) {
        return (
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <TextField error={error && error.handle} key="handle" label="Split Handle" value={editingSplitHandle} connectedRight={<div style={{marginTop: "6px"}}>
                  <Tooltip content="Handles must be all lower case, and only contain letters, numbers, hyphens or underscores." dismissOnMouseOut>
                    <Icon source={CircleInformationMajor} color="base"/>
                  </Tooltip></div>}
                  onChange={(val) => { setEditingSplitHandle(val); setIsChanged(true); }}
                /><br/>
                {editingSplitProperties.map((e, idx) => {
                  return <Stack>
                    <FieldSpecifier idx={idx} onChange={(field) => {
                        let elements = Array.from(editingSplitProperties);
                        elements[idx] = field;
                        setEditingSplitProperties(elements);
                        setIsChanged(true);
                      } } field={e} level="variant" type="string"/>
                    <ButtonGroup>
                      {(idx > 0 && <Button disabled={productSplitsLoading} onClick={() => { setIsChanged(true); setEditingSplitProperties(editingSplitProperties.filter((e2, idx2) => idx2 !== idx)); } }>Remove Field</Button>)}
                      {idx === editingSplitProperties.length - 1 && <Button onClick={() => { setIsChanged(true); setEditingSplitProperties(editingSplitProperties.concat([null])); } }>Add another Field</Button>}
                    </ButtonGroup>
                    <div style={{marginTop: "8px"}}>
                      <Tooltip content="If you require more complicated split conditions, the Custom Field feature can be used to make arbitrary complicated merges. Contact support for details!" dismissOnMouseOut>
                        <Icon source={CircleInformationMajor} color="warning"/>
                      </Tooltip>
                    </div>
                  </Stack>
                })}
              </Card>
            </Layout.Section>
            {profile.shop.locales && profile.shop.locales.length > 1 && <Layout.Section secondary>
              <Card
                title='Locales'
              >
                <OptionList
                  selected={editingSplitLocales || profile.shop.locales.map((l) => l.code)}
                  onChange={(val) => { setIsChanged(true); setEditingSplitLocales(val); }}
                  options={profile.shop.locales.map((locale, idx1) => { return { label: locale.name, value: locale.code} })}
                  allowMultiple
                />
              </Card>
            </Layout.Section>}
          </Layout>
        );
      } else {
        return (
          <Layout>
            <Layout.Section>
              {!canUseProductSplitAndMerge && [<UpgradeBanner singular="Product Split"/>,<br/>]}
              <Card>
                <Card.Section>
                  <Button primary disabled={!canUseProductSplitAndMerge} onClick={() => {
                    setEditingSplitId(null);
                    setEditingSplitHandle('');
                    setEditingSplitProperties([null]);
                    setEditingSplitLocales(currentLocale && currentLocale.code);
                    setIsChanged(true);
                  }}>Add a product split</Button>
                </Card.Section>
                <Card.Section>
                  <MultiTable
                    resourceName={{
                      singular: "product split",
                      plural: "product splits"
                    }}
                    isEditing={isEditing}
                    hideTable={isEditing}
                    disabled={productSplitsLoading}
                    bulkActions={[{
                      content: "Delete splits",
                      onAction: (ids) => {
                        let productSplitHash = Object.fromEntries(ids.map((e) => [e,1]));
                        saveSplits(productSplits.filter((e, idx) => !productSplitHash[idx]));
                      }
                    }]}
                    headings={[
                      "Label",
                      "Fields",
                      (!currentLocale && profile.shop.locales.length > 1 && "Locales")
                    ]}
                    onRowClick={(row, idx) => {
                      setEditingSplitId(productSplits[idx].handle);
                      setEditingSplitHandle(productSplits[idx].handle);
                      setEditingSplitProperties(convertToFrontend(productSplits[idx].properties))
                      setEditingSplitLocales(productSplits[idx].locales);
                    }}
                    loading={!productSplitsLoaded}
                    rows={productSplits && productSplits.map((productSplit, idx) => [
                        productSplit.handle,
                        productSplit.properties.map((e) => <Badge key={e.type}>{e.type}</Badge>),
                        (!currentLocale && profile.shop.locales.length > 1 && (<Stack>{(productSplit.locales || profile.shop.locales.map((l) => l.code)).sort().map((l, idx) => (<Badge key={`badge-${idx}`}>{l}</Badge>))}</Stack>))
                    ])}
                  />
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        );
      }
    }}
    </Page>
  );
}


