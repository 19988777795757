import React, {useState, useEffect, useCallback} from 'react';
import { MultiTable, Auth, useRedirect } from "admin-frontend";
import {
  Link,
  Badge,
  Card,
  Button,
  ButtonGroup,
  Tooltip,
  Banner,
  Icon
} from '@shopify/polaris';
import { RiskMajor } from '@shopify/polaris-icons';

import { Page } from "../../components/Page";
import { localeFormat, LocalizedBadgeList } from "../../components/Localization";
import { ActivityProvider, ActivityBanner } from "../../components/Activity";
import { Stack } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";

export function Facets({ themes }) {
  const [profile] = Auth.useProfile();
  const redirect = useRedirect();
  const [facets, setFacets] = useState(null);
  const [orphanedFacets, setOrphanedFacets] = useState(null);
  const [facetGroups, setFacetGroups] = useState(null);
  const [facetCounts, setFacetCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(0);
  const [activity, setActivity] = useState({});

  const authFetch = useSpotFetch();


  useEffect(() => {
    if (!facets) {
      authFetch("/api/facets", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setFacets(r.facets);
          setFacetCounts(r.counts);
          setActivity(r.activity);
          setIsLoading(!facetGroups);
        });
    }
  }, [facets, theme, authFetch]);



  useEffect(() => {
    if (!orphanedFacets) {
      authFetch("/api/facets/orphaned", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setOrphanedFacets(r.facets);
        });
    }
  }, [facets, theme, authFetch]);

  useEffect(() => {
    if (!facetGroups) {
      authFetch("/api/facets/groups", { query: theme ? { theme_id: theme.id } : {} })
        .then((r) => {
          setFacetGroups(r.groups);
          setIsLoading(!facets);
        });
    }
  }, [facetGroups, theme, authFetch]);

  const deleteFacets = useCallback((ids) => {
    return authFetch("/api/facets", { method: "DELETE", json: { ids: ids } })
      .then((r) => {
        setIsLoading(false);
        var removed = Object.fromEntries(ids.map((e) => [e, 1]));
        setFacets(facets.filter((f) => !removed[f.id]));
      });
  }, [authFetch, facets]);

  const resourceName = {
    singular: "facet",
    plural: "facets"
  };

  const facetGroupHash = {};
  if (facetGroups) {
    facetGroups.forEach((facetGroup) => {
      facetGroup.facets.forEach((fid) => {
        if (!facetGroupHash[fid])
          facetGroupHash[fid] = [];
        facetGroupHash[fid].push(facetGroup);
      })
    })
  }

  return (
    <Page
      title="Facets"
      subtitle={"Create facets your customers can use to narrow down their collection or search results."}
      localization
      overrideThemes
      permission="facets"
      themes={themes}
      resourceName={resourceName}
      setTheme={(newTheme) => { if (!isLoading && theme !== newTheme) { setFacets(null); setIsLoading(true); setTheme(newTheme); } }}
      disabled={isLoading}
      getResourceCount={(themeId) => facetCounts[themeId || 0]}
      onPort={(sourceId, targetId, preserve) => {
        setIsLoading(true);
        authFetch("/api/facets/port", { json: { preserve: preserve, from: sourceId, to: targetId } })
          .then((r) => {
            setFacets(null);
            setTheme(targetId);
            setIsLoading(false);
          });
      }}
      onDelete={(theme) => {
        deleteFacets(facets.map((f) => f.id))
          .then((r) => {
            setFacets(null);
            setTheme(0);
            setIsLoading(false);
        });
      }}
    >
      {(currentTheme, currentLocale) => {
        let validFacets = facets && facets.filter((e) => !e.locales || e.locales.length === 0 || e.locales.filter((l) => l === currentLocale ? currentLocale.code : "en").length > 0);

        return (<>
          {orphanedFacets && orphanedFacets.length > 0 && (<><Banner
            title="Unassigned Facet Values"
            status="warning"
            action={{content: 'View Unassigned Values', url: '/facets/orphaned'}}
            secondaryAction={{content: 'Suppress Unassigned Warnings for ' + orphanedFacets.map((f) => localeFormat(currentLocale, f.name)).join(", "), onAction: () => {
              setIsLoading(true);
              authFetch('/api/facets/orphaned', { json: { facets: orphanedFacets.map((f) => { return { id: f.id, orphaned: false }; }) } }).then((r) => {
                setOrphanedFacets(r.facets);
                setIsLoading(false);
              })
            }}}
          >
            Your active {orphanedFacets.length > 1 ? ("facets " + orphanedFacets.map((f) => localeFormat(currentLocale, f.name)).join(", ") + " have") : ("facet " + orphanedFacets.map((f) => localeFormat(currentLocale, f.name)).join(", ") + " has")} orphaned values. These values will not be available for your customers to select.
          </Banner><br/></>)}
          <Card>
            <Card.Section>
              <Stack alignment="center">
                <Stack.Item fill>
                  <Button onClick={() => { redirect("/facets/new"  + (currentTheme ? "?theme_id=" + currentTheme.id : "")); } } primary>
                    Add a {resourceName.singular}
                  </Button>
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <ActivityProvider activity={activity} objectType={"facet"} setActivity={setActivity}><MultiTable
                newable={true}
                resourceName={resourceName}
                bulkActions={[{
                  content: "Delete facets",
                  onAction: (ids) => deleteFacets(ids.map((idx) => validFacets[idx].id))
                }]}
                headings={[
                  "Name",
                  "Property",
                  "Type",
                  "Applied Groups",
                  (!currentLocale && "")
                ]}
                rows={validFacets && validFacets.map((facet, idx) => { return [
                  (<Stack alignment="center">
                    <Stack.Item fill>{localeFormat(currentLocale, facet.name)}</Stack.Item>
                    <ActivityBanner activity={activity[facet.id]} objectId={facet.id}/>
                  </Stack>),
                  facet.level,
                  facet.type,
                  (<LocalizedBadgeList locale={currentLocale} values={facetGroupHash && facetGroupHash[facet.id] && facetGroupHash[facet.id].map((fg) => fg.name)} emptyTooltip={"Because this facet is not part of any facet group, it will never be shown on the customer-facing part of your website. To use this facet, add it to a facet group on the facet group page."}/>),
                  (!currentLocale && profile.shop.locales.length > 1 && (<Stack>{(facet.locales || profile.shop.locales.map((l) => l.code)).sort().map((l, idx) => (<Badge key={`badge-${idx}`}>{l}</Badge>))}</Stack>))
                ] })}
                onRowClick={(row, idx) => {
                  redirect("/facets/" + validFacets[idx].id + (currentTheme ? "?theme_id=" + currentTheme.id : ""));
                }}
              /></ActivityProvider>
            </Card.Section>
          </Card>
        </>);
      }}
    </Page>
  );
}
