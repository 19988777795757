import React, {useState, useEffect, useMemo } from 'react';
import {
  Card,
  Button,
  Select,
  ButtonGroup,
  FormLayout,
  Icon,
  Checkbox,
  RangeSlider,
  Badge,
  TextField,
  Tooltip
} from '@shopify/polaris';
import { MultiTableForm, MultiTable, rearrangeArray, useRedirect, Stack } from "admin-frontend";
import { useParams, useLocation } from "react-router-dom";

import { useSpotFetch } from "../useSpotFetch";
import { FieldSpecifier, productFields, titleCase } from "../components/Fields"
import { LocalizedBadgeList, localeFormat } from "../components/Localization"
import { EntryRangeSlider } from "../components/EntryRangeSlider"
import { Page } from "../components/Page"
import { DeleteMajor, CircleInformationMajor } from '@shopify/polaris-icons';

export function BoostRuleEditor({ currentTheme, currentLocale, onPort, subtitle, title, boostRule, isChanged, setBoostRule, error, setError, ai }) {
  function renderRule(isAdvanced, idx) {
    let rule = boostRule.rules[idx];
    const availableProductFields = productFields.filter((f) => /^(tag|variant-metafield|available|product-metafield|product-custom-field|category|variant-custom-field|option|option1|option2|option3|collection|vendor|product_type|title)$/.test(f.handle));
    let availableSourceFields = rule.source === "customer" ? [
       {
        "name": "Tag",
        "handle": "tag",
        "level": "customer",
        "type": "list",
        "specifiers": [],
      }, {
        "name": "Recently Viewed",
        "handle": "recently-viewed",
        "level": "customer",
        "type": "product",
        "specifiers": [],
      }/*,
      {
        "name": "Country",
        "handle": "country",
        "level": "customer",
        "type": "string",
        "specifiers": []
      }*/
    ] : (rule.source === "session" ? [
      {
        "name": "URL Parameter",
        "handle": "url_param",
        "level": "session",
        "type": "list",
        "specifiers": ["param"]
      }
    ] : availableProductFields.filter((f) => !/variant-custom-field/.test(f.handle)));
    if (rule.source === "static") {
      availableSourceFields.push({
        "name": "Product",
        "handle": "id",
        "level": "product",
        "type": "product",
        "specifiers": []
      });
      availableSourceFields.push(availableProductFields.filter((f) => /^available$/.test(f.handle))[0]);
    }
    if (rule.source === "product") {
      availableSourceFields.push({
        "name": "Manual List",
        "handle": "manual-list",
        "level": "product",
        "type": "product",
        "specifiers": []
      });
      if (ai.relatedness) {
        availableSourceFields.push({
          "name": "AI Similarity",
          "handle": "related",
          "level": "product",
          "type": "product",
          "specifiers": []
        });
      }
    }
    const sourceField = availableSourceFields.filter((f) => f.handle === rule.source_field[0])[0];
    const showTarget = rule.source !== "static" && rule.source_field[0] !== "title" && rule.source_field[0] !== "manual-list" && rule.source_field[0] !== "recently-viewed";
    const showMatch = showTarget && rule.source !== "session";
    let availableMapFields = [...(rule.source === "session" ? availableProductFields : availableSourceFields)];
    availableMapFields.push({
      "name": "Product",
      "handle": "id",
      "level": "product",
      "type": "product",
      "specifiers": []
    });
    availableMapFields = availableMapFields.filter((f) => f.handle != "manual-list");

    let options = [
      (<Select value={rule.source} onChange={(val) => {
        rule.source = val;
        rule.source_field = val !== "session" ? ["tag"] : ["url_param"];
        if (val === "session") {
          rule.mapping = "map";
          rule.target_field = [availableMapFields[0].handle];
        }
        setBoostRule({ ...boostRule });
      }} options={[{ label: "Product (on PDP)", value: "product" }, { label: "Static", value: "static" }, { label: "Customer", value: "customer" }, { label: "Session", value: "session" }]}/>),
      (<FieldSpecifier chooseFromList={!rule.source_field || rule.source_field[0] !== "collection"}  chooseValue={(rule.source === "static" && rule.source_field[0] != "available") || rule.mapping === "map" || rule.mapping === "nomap"} onChange={(val) => {
        rule.source_field = val;
        setBoostRule({ ...boostRule });
      }} fields={availableSourceFields} field={rule.source_field}/>),
    ];
    if (showTarget) {
      options = options.concat([
        <Select value={rule.mapping || "match"} onChange={(val) => {
          rule.mapping = val;
          if (val === "map" || val === "nomap")
            rule.target_field = [availableMapFields[0].handle];
          else if (rule["target_field"])
            delete rule["target_field"]
          setBoostRule({ ...boostRule });
        }} options={[...(showMatch ? [{ label: "matches", value: "match" }, { label: "doesn't match", value: "nomatch" }] : []), { label: "maps", value: "map" }, { label: "doesn't map", value: "nomap" }]}/>,
        (rule.target_field ? (<FieldSpecifier fields={availableMapFields} chooseFromList={true} chooseValue={true} onChange={(val) => {
          rule.target_field = val;
          setBoostRule({ ...boostRule });
        }} field={rule.target_field} />) : <Select disabled options={[sourceField.name]}/>),
      ]);
    } else {
      options = options.concat([
        <Select value={rule.mapping || "match"} onChange={(val) => {
          rule.mapping = val;
          if (val === "map" || val === "nomap")
            rule.target_field = [availableMapFields[0].handle];
          else if (rule["target_field"])
            delete rule["target_field"]
          setBoostRule({ ...boostRule });
        }} options={[{ label: "matches", value: "match" }, { label: "doesn't match", value: "nomatch" }]}/>,""
      ]);
    }
    if (isAdvanced) {
      options.push(<EntryRangeSlider style={{"minWidth": "256px", "marginTop": "24px"}} output min={-1000} max={1000} value={rule.weight || 0}
							suffix={<div style={{ minWidth: "32px" }}>{rule.weight || 0}</div>} onChange={(v) => { rule.weight = parseFloat(v); setBoostRule({ ...boostRule }); }}/>);
    }
    options.push(<Checkbox checked={boostRule.rules[idx].mandatory || false} onChange={(val) => { boostRule.rules[idx].mandatory = val; setBoostRule({ ...boostRule }); }} />);
    options.push(<button type="button" className="Polaris-Button" style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => {
      boostRule.rules = boostRule.rules.filter((e, idx2) => idx2 !== idx);
      setBoostRule({ ...boostRule });
    }}>
      <Icon source={DeleteMajor} color="base" />
    </button>);
    return options;
  }

  const isAdvanced = boostRule.rules.filter((e) => e.weight != null).length > 0;

	return (<>
    <Card title="Settings" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField label="Name" error={error.name} onChange={(val) => {
            setError({ ...error, name: null });
            setBoostRule({ ...boostRule, name: val });
          }} value={boostRule.name} placeholder="Boost Rule Name"/>
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField multiline={4} label="Description" placeholder="Optional freeform description for this boost rule. Does not affect functionality." onChange={(val) => {
            setBoostRule({ ...boostRule, description: val });
          }} value={boostRule.description}/>
        </FormLayout.Group>
      </FormLayout>
    </Card>
    <Card title={boostRule.rules.length > 0 && (isAdvanced ? "Advanced" : "Basic") + " Matching Rules"} actions={boostRule.rules.length > 0 && [{ content: "Switch to " + (!isAdvanced ? "Advanced" : "Basic"), onAction: () => {
      setBoostRule({ ...boostRule, rules: boostRule.rules.map((e) => {
        if (!isAdvanced)
          return { ...e, weight: 0 };
        else
          return { ...e, weight: null };
      }) });
    } }]}>
      {boostRule.rules.length > 0 && <Card.Section>
        {!isAdvanced ? (
          <p>Basic boost rules have rules ordered by level of importance. Rules higher in the list will be given more weight when applying boost rules.</p>
        ) : (
          <p>Advanced rules have rules which manually set their rule weights.</p>
        )}
      </Card.Section>}
      <Card.Section>
        <MultiTable
          error={error.rules}
          onDragDrop={!isAdvanced && ((source, destination) => {
            boostRule.rules = rearrangeArray(boostRule.rules, source, destination);
            setBoostRule({ ...boostRule })
          })}
          condensed
          headings={[
            ...[(
              <Stack alignment="center">
                <span>Source</span>
                <Tooltip content="Where we draw the information from the boost. 'Product' will only draw information if on a PDP; usually used for recommendations. 'Static' will draw information only from the rule itself. 'Customer' will draw information from the customer object, only if the customer is logged in." dismissOnMouseOut>
                  <Icon source={CircleInformationMajor} color="base"/>
                </Tooltip>
              </Stack>
            ), "Source Field", "Type", "Product Field"],
            ...(isAdvanced ? ["Weight", "Required", ""] : ["Required", ""])
          ]}
          rows={[
            ...(boostRule && boostRule.rules ? boostRule.rules.map((r, idx) => renderRule(isAdvanced, idx)) : [])
          ]}
          resourceName={{singular:"rule", plural:"rules"}}
        />
        <br/>
        <ButtonGroup>
          <Button disabled={!boostRule || boostRule.rules.length == 0} onClick={() => {
            setBoostRule({ ...boostRule, rules: [...boostRule.rules, { ...boostRule.rules[boostRule.rules.length-1] }] });
          }}>Duplicate Last Rule</Button>
          <Button primary onClick={() => {
            setBoostRule({ ...boostRule, rules: [...boostRule.rules, {
              source: "product",
              type: "match",
              source_field: ["tag"],
              mapping: "match"
            }] });
          }}>Add a Rule</Button>
        </ButtonGroup>
      </Card.Section>
    </Card>
  </>);
}

export function BoostRules() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [boostRules, setBoostRules] = useState(null);
  const [boostRule, setBoostRule] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [rulesets, setRulesets] = useState(null);
  const [ai, setAI] = useState(null);
  const [error, setError] = useState({});

  const authFetch = useSpotFetch();
  const redirect = useRedirect();
  const { boostRuleId } = useParams();


  useEffect(() => {
    if (!recommendations) {
      authFetch("/api/recommendations").then((r) => {
        setIsLoading(false);
        setRecommendations(r.recommendations);
      });
    }
  }, [recommendations, authFetch]);


  useEffect(() => {
    if (!rulesets) {
      authFetch("/api/global/rulesets").then((r) => {
        setIsLoading(false);
        setRulesets(r.rulesets);
      });
    }
  }, [rulesets, authFetch]);

  useEffect(() => {
    if (!boostRules) {
      authFetch("/api/global/boost_rules").then((r) => {
        setIsLoading(false);
        setBoostRules(r.boost_rules);
      });
    }
  }, [boostRules, authFetch]);


  useEffect(() => {
    if (!boostRules) {
      authFetch("/api/setup/ai").then((r) => {
        setIsLoading(false);
        setAI(r.ai);
      });
    }
  }, [boostRules, authFetch]);

  const needsToRedirect = ai && boostRules && ((boostRuleId && (!boostRule || (boostRuleId != "new" && boostRule.id != boostRuleId) || (boostRuleId == "new" && boostRule.id)) || (!boostRuleId && boostRule)));
  const recommendationHash = recommendations && Object.fromEntries(recommendations.map((r) => [r.id, r]));
  const rulesetHash = rulesets && Object.fromEntries(rulesets.map((r) => [r.id, r]));

  useEffect(() => {
    if (needsToRedirect) {
      const selectedBoostRule = boostRuleId && boostRuleId != "new" && boostRules.filter((br) => br.id == boostRuleId)[0];
      setBoostRule(selectedBoostRule ? selectedBoostRule : (boostRuleId ? { rules: [], limit: "4" } : null));
      setIsLoading(false);
    }
  }, [boostRuleId, needsToRedirect]);

  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  return (<Page
    fullWidth
    resourceName={{singular: "Boost Rule", plural: "Boost Rules"}}
    title="Boost Rule Settings"
    permission="boost-rules"
    audit={boostRule && boostRule.id && {resource: "BoostRule", id: boostRule.id}}
    subtitle="Tell Spot what catalog attributes should be used to boost products in collection, search results and recommendations."
    isChanged={isChanged}
    isLoading={isLoading || needsToRedirect || !recommendationHash}
    onPort={() => { }}
    // onBack={boostRule && (() => { redirect('/globals/boost_rules'); })}
    onBack={boostRule != null && (() => {
      redirect(params.get("from") || '/globals/boost_rules');
    })}
    onSave={boostRule && ((theme) => {
      if (boostRule.name == null || /^\s*$/.test(boostRule.name)) {
        setError({ ...error, name: "Requires a name." });
        return;
      }
      if (boostRule.rules.length === 0) {
        setError({ ...error, rules: "Requires at least one rule." });
        return;
      }
      setIsLoading(true);
      authFetch("/api/global/boost_rules" + (boostRule.id ? "/" + boostRule.id : ""), { json: boostRule }).then((r) => {
        setIsLoading(false);
        setIsChanged(false);
        setError({});
        setBoostRules(!boostRule.id ? [...boostRules, { ...r, applied: 0 }] : boostRules.map((e,idx) => e.id === r.id ? { ...r, applied: e.applied } : e));
        redirect(params.get("from") || '/globals/boost_rules');
      });
    })}
    overrideThemes
    localization
    disableThemes
  >
    {(currentTheme, currentLocale) => (<>
      {boostRule && (<BoostRuleEditor
        error={error}
        setError={setError}
        ai={ai}
        setBoostRule={(boostRule) => {
          setBoostRule(boostRule);
          setIsChanged(true);
        }}
        boostRule={boostRule}
      />)}
      {!boostRule && (<MultiTableForm
        bulkActions={[{
          content: "Delete Boost Rules",
          onAction: (ids) => {
            authFetch("/api/global/boost_rules", { method: "DELETE", json: { ids: ids.map((idx) => boostRules[idx].id) }}).then((r) => {
              let deleted = Object.fromEntries(ids.map((i) => [i,1]));
              setBoostRules(boostRules.filter((e, idx) => !deleted[idx]));
            });
          }
        }]}
        onNew={() => { redirect('/globals/boost_rules/new'); }}
        resourceName={{singular: "Boost Rule", plural: "Boost Rules"}}
        headings={[
          "Name",
          "Applied Locations"
        ]}
        loading={isLoading || needsToRedirect || !recommendationHash}
        onRowClick={(row, idx) => { redirect("/globals/boost_rules/" + boostRules[idx].id); }}
        rows={boostRules && boostRules.map((r) => [
          r.name,
          (recommendationHash && rulesetHash && <LocalizedBadgeList locale={currentLocale} maxToDisplay={5} values={[
            ...r.recommendation_ids.map((id) => <Badge status="success">{localeFormat(currentLocale, recommendationHash[id].name)}</Badge>),
            ...r.ruleset_ids.filter((id) => rulesetHash[id]).map((id) => <Badge status="warning">{localeFormat(currentLocale, rulesetHash[id].handle)}</Badge>)
          ]}/>),
        ])}
      />)}
    </>)}
  </Page>);
}

