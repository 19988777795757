import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/dist/styles.css';
import 'admin-frontend/dist/esm/styles.css';

import { App, Redirect, InstallPage, useRedirect, PermissionsPage } from "admin-frontend";
import jwtDecode from "jwt-decode";
import {
  Page,
  TextContainer,
  Layout
} from '@shopify/polaris';


export function NotFound() {

    return (
        <Page
        title="404"
        subtitle={"There's no page at this address. Check the URL and try again, or use one of the navigation options above."}
        >
        </Page>
    );
}
