import React, {useState, useEffect} from 'react';
import {
  Layout,
  Button,
  ButtonGroup,
  TextField,
  Badge,
  Tooltip,
  Icon,
  Select
} from '@shopify/polaris';
import { MultiTableForm, Auth, Stack } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { productFields, FieldSpecifier } from "../../components/Fields"
import { Page } from "../../components/Page"
import { UpgradeBanner } from "../../components/UpgradeBanner";
import { CircleInformationMajor } from '@shopify/polaris-icons';

export function ProductMerges() {
  const [error, setError] = useState({});
  const [productMerges, setProductMerges] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [productMergesLoading, setProductMergesLoading] = useState(true);
  const [productMergesLoaded, setProductMergesLoaded] = useState(false);
  const [editingMergeId, setEditingMergeId] = useState(null);
  const [editingMergeHandle, setEditingMergeHandle] = useState([]);
  const [editingMergeProperties, setEditingMergeProperties] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [profile] = Auth.useProfile();

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!productMergesLoaded) {
      authFetch("/api/global/product_merges")
        .then((r) => {
          setProductMerges(Object.keys(r.product_merges).sort().map((e) => r.product_merges[e]));
          setUpdatedAt(r.updated_at);
          setProductMergesLoaded(true);
          setProductMergesLoading(false);
      });
    }
  }, [productMergesLoaded, authFetch]);

  function saveMerges(productMerges) {
    let productMergeHash = {};
    productMerges.forEach((e) => productMergeHash[e.handle] = e);
    setProductMergesLoading(true);
    authFetch("/api/global/product_merges", { json: { product_merges: productMergeHash, updated_at: updatedAt } })
      .then((r) => {
        setProductMerges(Object.keys(r.product_merges).sort().map((e) => r.product_merges[e]));
        setUpdatedAt(r.updated_at);
        setProductMergesLoading(false);
      });
  }

  function convertToFrontend(properties) {
    return properties.map((e) => {
      if (e.type === "product-metafield")
        return [e.type, e.namespace, e.key];
      if (e.type === "product-custom-field")
        return [e.type, e.field];
      else if (e.type === "tag")
        return [e.type, e.tag];
      return [e.type];
    });
  }

  function convertFromFrontend(properties) {
    return properties.map((s) => {
      const field = productFields.filter((f) => f.handle === s[0])[0];
      let property = {
        type: field.handle
      };
      if (field.handle === "product-metafield") {
        property["namespace"] = s[1];
        property["key"] = s[2];
      } else if (field.handle === "product-custom-field") {
        property["field"] = s[1];
      } else if (field.handle === "tag") {
        property["tag"] = s[1];
      }
      return property;
    });
  }

  const defaultMerge = productMerges.filter((e) => e.default)[0];
  let isEditing = editingMergeProperties.length !== 0;
  const canUseProductSplitAndMerge = profile?.shop?.active_subscription?.features?.split_and_merge?.value !== false;

  return (
  <Page
    title="Product Merges"
    permission="merges-and-splits"
    audit={{resource: "ProductMerge"}}
    subtitle="Treat multiple products as a single product tile, based on a common attribute (up to 2000 variants per merged product)."
    resourceName={{ singular: "product merge", plural: "product merges" }}
    isChanged={isChanged}
    disableThemes
    onBack={isEditing && (() => { setEditingMergeProperties([]) })}
    onSave={isEditing && (() => {
      setError({});
      if (!editingMergeHandle || /^\s*$/.test(editingMergeHandle)) {
        setError({ ...error, handle: "Requires a handle." });
        return;
      }
      if (!/^[a-z0-9\-_]+$/.test(editingMergeHandle)) {
        setError({ ...error, handle: "Handle must only constist of lower-case letters, numbers, hyphens or underscores." });
        return;
      }
      saveMerges([...productMerges.filter((e) => !editingMergeId || editingMergeId !== e.handle),  { handle: editingMergeHandle, properties: convertFromFrontend(editingMergeProperties), locales: [] }]);
      setEditingMergeId(null);
      setEditingMergeHandle('');
      setEditingMergeProperties([]);
      setIsChanged(false);
    })}
  >
    {(currentTheme, currentLocale) => {
      return (
        <Layout>
          <Layout.Section>
            {!canUseProductSplitAndMerge && [<UpgradeBanner singular="Product Merge"/>,<br/>]}
            <MultiTableForm
              resourceName={{
                singular: "product merge",
                plural: "product merges"
              }}
              isEditing={isEditing}
              hideTable={isEditing}
              disabled={productMergesLoading}
              onNew={canUseProductSplitAndMerge ? (() => {
                setEditingMergeId(null);
                setEditingMergeHandle('');
                setEditingMergeProperties([null]);
                setIsChanged(true);
              }) : null}
              editingForm={[
                (<TextField error={error && error.handle} key="handle" label="Merge Handle" value={editingMergeHandle} connectedRight={<div style={{marginTop: "6px"}}><Tooltip content="Handles must be all lower case, and only contain letters, numbers, hyphens or underscores." dismissOnMouseOut>
                  <Icon source={CircleInformationMajor} color="base"/>
                </Tooltip></div>}
                onChange={(val) => { setIsChanged(true); setEditingMergeHandle(val); }}/>),
                editingMergeProperties.map((e, idx) => {
                  return <Stack>
                    <FieldSpecifier idx={idx} onChange={(field) => {
                        let elements = Array.from(editingMergeProperties);
                        elements[idx] = field;
                        setEditingMergeProperties(elements);
                        setIsChanged(true);
                      } } field={e} fields={productFields.filter((e) => ["product-metafield", "title", "product_type", "vendor", "tag", "product-custom-field"].includes(e.handle))} level="product" type={["string","list"]} chooseFromList={true}/>
                    <ButtonGroup>
                      {(idx > 0 && <Button disabled={productMergesLoading} onClick={() => { setIsChanged(true); setEditingMergeProperties(editingMergeProperties.filter((e2, idx2) => idx2 !== idx)); } }>Remove Field</Button>)}
                      {idx === editingMergeProperties.length - 1 && <Button onClick={() => { setIsChanged(true); setEditingMergeProperties(editingMergeProperties.concat([null])); } }>Add another Field</Button>}
                    </ButtonGroup>
                    <div style={{marginTop: "8px"}}>
                      <Tooltip content="If you require more complicated merge conditions, the Custom Field feature can be used to make arbitrary complicated merges. Contact support for details!" dismissOnMouseOut>
                        <Icon source={CircleInformationMajor} color="warning"/>
                      </Tooltip>
                    </div>
                  </Stack>
                })
              ]}
              beforeTable={productMerges.length > 0 && <><Select
                label="Default Merge"
                options={[{label:"None", value:"none"}].concat(productMerges.map((e) => { return { label: e.handle, value: e.handle }; }))}
                value={defaultMerge ? defaultMerge.handle : "none"}
                onChange={(handle) => { setIsChanged(true); saveMerges(productMerges.map((e) => { return { ...e, default: e.handle === handle }; }))}}
              /><br/></>}
              bulkActions={[{
                content: "Delete merges",
                onAction: (ids) => {
                  let productMergeHash = Object.fromEntries(ids.map((e) => [e,1]));
                  saveMerges(productMerges.filter((e, idx) => !productMergeHash[idx]));
                }
              }]}
              headings={[
                "Label",
                "Fields"
              ]}
              onRowClick={(row, idx) => {
                setEditingMergeId(productMerges[idx].handle);
                setEditingMergeHandle(productMerges[idx].handle);
                setEditingMergeProperties(convertToFrontend(productMerges[idx].properties));
              }}
              loading={!productMergesLoaded}
              rows={productMerges && productMerges.map((productMerge, idx) => [
                  productMerge.handle,
                  productMerge.properties.map((e) => <Badge key={e.type}>{e.type}</Badge>)
              ])}
            />
          </Layout.Section>
        </Layout>
      );
    }}
    </Page>
  );
}


