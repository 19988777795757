import React, {useState, useEffect, useCallback, useMemo } from 'react';
import {
  TextContainer,
  Icon,
  Spinner,
  Button,
  Modal,
  Checkbox,
  Filters,
  Thumbnail
} from '@shopify/polaris';
import { Auth, MultiTable, Stack } from "admin-frontend";
import { useSpotAPI } from "./API"
import { SoftPackMajor, SearchMajor } from '@shopify/polaris-icons';
import { PlaceholderImage } from "./Page";
import { titleCase, FieldSpecifier, CachedTextField, productFields } from "./Fields";

export function ResourcePickerButton({ label, primary, fullWidth = true, disabled, onCancel, resourceType = "product", initialSelectionIds, type = "select", split, selectMultiple, onSelection, ...others }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState(initialSelectionIds);
  const [cachedInfo, setCachedInfo] = useState({});
  const [attemptedIds, setAttemptedIds] = useState({});

  const spotAPI = useSpotAPI();

  const target = resourceType == "variant" ? "products": (resourceType.toLowerCase() + "s");
  const identifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  const needsToLoad = type == "select" && selectedIds && selectedIds.length > 0 && selectedIds.filter((id) => cachedInfo[id] == null && !attemptedIds[id]).length > 0;

  useEffect(() => {
    if (needsToLoad) {
			spotAPI.s()[identifierField]("in", selectedIds).targets([target]).split(split).e().done((items) => {
        setAttemptedIds({ ...attemptedIds, ...Object.fromEntries(selectedIds.map((id) => [id, true])) });
        if (resourceType == "variant") {
          setCachedInfo({ ...cachedInfo, ...Object.fromEntries(items.flatMap((i) => { return i.variants.map((v) => { return { ...v, product: i }; }) }).map((i) => [i.id, i.product.handle + "-" + i.id])) });
        } else {
          setCachedInfo({ ...cachedInfo, ...Object.fromEntries(items.map((i) => [i[identifierField], (split ? (i.handle + "-" + i[identifierField]) : i.handle)])) });
        }
				selectedIds.filter((id) => cachedInfo[id] == null).forEach((id) => { cachedInfo[id] = false; });
			});
		}
  }, [needsToLoad]);

  const capitalized = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);

  return (<span>
    <Button disabled={disabled} fullWidth={fullWidth} primary={primary} loading={needsToLoad} onClick={() => setIsOpen(true)}>
      {
        label ||
        (type == "select" && selectedIds && selectedIds.length > 0 && (selectedIds.map((id) => cachedInfo[id] ? cachedInfo[id] : id).join(", "))) ||
        ((type == "select" ? "Select" : "Add") + (!selectMultiple ? (" a " + capitalized) : (" " + capitalized + "s")))
      }
    </Button>
    <ResourcePicker
      open={isOpen}
      title={label}
      onCancel={({ ...others }) => {
        setIsOpen(false);
        if (onCancel) {
          onCancel({ ...others });
        }
      } }
      onSelection={({ selection, ...others }) => {
        setIsOpen(false);
        setCachedInfo({ ...cachedInfo, ...Object.fromEntries(selection.map((i) => [i[identifierField], (split ? (i.handle + "-" + i[identifierField]) : i.handle)])) })
        setSelectedIds(selection.map((i) => (i[identifierField])));
        onSelection({ selection, ...others });
      } }
      resourceType={resourceType}
      selectMultiple={selectMultiple}
      initialSelectionIds={initialSelectionIds}
      split={split}
      {...others}
    />
  </span>)
}

export function ResourceTable({ resourceType = "product", initialSelectionIds, initialQuery, selectMultiple, row, beforeQueryRun, onRowClick, onSelection, items, setItems, onCancel, onClick, split, headings, filters, appliedFilters }) {
  const [profile] = Auth.useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(initialQuery);
  const [internalItems, internalSetItems] = useState(null);

  const multiplexSetItems = setItems || internalSetItems;
  const multiplexItems = items || internalItems;

  const spotAPI = useSpotAPI();


  const identifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  const maxSelection = typeof(selectMultiple) == "number" ? selectMultiple : (selectMultiple ? null : 1);
  const target = resourceType == "variant" ? "products": (resourceType.toLowerCase() + "s");

  const runQuery = useCallback((query, page, resourceType, beforeQueryRun) => {
    var sq = spotAPI.s().allVariants(false).page(page).targets([target]);
    if (query)
      sq = sq.search(query);
    if (beforeQueryRun)
      sq = beforeQueryRun(sq);
    sq.e().done((newItems) => {
      multiplexSetItems(page === 1 ? newItems : [...(multiplexItems || []), ...newItems]);
    });
  }, [multiplexItems, multiplexSetItems, spotAPI]);

	useEffect(() => {
    runQuery(query, page, resourceType, beforeQueryRun);
	}, [query, page, resourceType, beforeQueryRun]);

	function toggleItem(item) {
    if (!selectedItems[item[identifierField]]) {
      if (maxSelection === null || Object.keys(selectedItems).length < maxSelection)
        setSelectedItems({ ...selectedItems, [item[identifierField]]: item });
    } else {
      delete selectedItems[item[identifierField]]
      setSelectedItems({ ...selectedItems });
    }
	}

	const scrolledToBottom = useCallback(() => {!isLoading && setPage(page+1)}, [isLoading, setPage, page]);

  const hasSelections = maxSelection > 0;
  const canSelectMore = Object.keys(selectedItems).length < maxSelection;

  return (<>
    {!multiplexItems && <Stack alignment="center" distribution="center"><Spinner size="large"/></Stack>}
    {multiplexItems && <>
      <TextContainer>
        <Filters
          queryValue={query}
          filters={filters || []}
          appliedFilters={appliedFilters || []}
          onQueryChange={(val) => { setQuery(val); setPage(1); }}
          onQueryClear={() => { setQuery(''); setPage(1); }}
          onClearAll={() => {}}
        />
      </TextContainer>
      <br/>
      <MultiTable
        headings={headings}
        onRowClick={onRowClick || ((row, idx) => { toggleItem(multiplexItems[idx]); if (onClick) { onClick(multiplexItems[idx]) } }) }
        rows={multiplexItems.map((item) => {
          const selection = hasSelections ? (selectedItems[item[identifierField]] || (canSelectMore ? false : null)) : null;
          const image = spotAPI.getSizedImage(resourceType === "collection" ? item.image : spotAPI.getProductImage(item), "64x64");
          return [
            ...(hasSelections > 0 ? [(selection != null ? (
              <Checkbox checked={selection} /*onChange={() => toggleItem(item)}*//>
            ) : (<span/>))] : []),
            ...(row ? row(item) : [
              (image ? (<Thumbnail
                source={image}
                size="medium"
                alt={resourceType === "collection" ? item.title : spotAPI.getProductTitle(item)}
              />) : <PlaceholderImage/>),
              resourceType === "collection" ? item.title : spotAPI.getProductTitle(item)
            ])
          ];
        })}
      />
    </>}
  </>);
}

export function ResourcePicker({ open, title, resourceType, initialSelectionIds, additive, initialQuery, headings, selectMultiple, beforeQueryRun, onSelection, onCancel, split, onClear, row, transform, allowNoSelection }) {
  const [isLoading, setIsLoading] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadField, setUploadField] = useState(null);
  const [uploadValue, setUploadValue] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, _setPage] = useState(1);
  const [query, _setQuery] = useState(initialQuery);
  const [items, setItems] = useState(null);
  const sessionTokenFetch = Auth.useFetchSessionToken();
  const [profile] = Auth.useProfile();

  const maxSelection = typeof(selectMultiple) == "number" ? selectMultiple : (selectMultiple ? null : 1);
  const spotAPI = useSpotAPI();


  const identifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  const currentTransform = transform || ((fn, items) => { fn(items); });
  const target = resourceType == "variant" ? "products": (resourceType.toLowerCase() + "s");

  const selectedItemHash = Object.fromEntries(selectedItems.map((i) => [i[identifierField], i]));

  const runUploadQuery = useCallback((uploadField, uploadValue) => {
    if (!isLoading && uploadValue && uploadField) {
      setIsLoading(true);
      var sq = spotAPI.s().allVariants(false).targets([target]);
      let valueList;
      if (maxSelection)
        sq = sq.paginate(maxSelection);
      let queryMaker = sq;
      uploadField.forEach((field) => queryMaker = queryMaker[field]);
      valueList = uploadValue.split(/\s*[,\s]\s*/).filter((v) => v != "");
      sq = queryMaker.call(sq, "in", valueList)
      if (split)
        sq.split(split);
      if (beforeQueryRun)
        sq = beforeQueryRun(sq);
      sq.e().done((newItems) => {
        const field = productFields.filter((f) => f.handle == uploadField[0]);
        const valueHash = Object.fromEntries(valueList.map((v, idx) => [v, idx]));
        const productHash = {};
        if (field.level == "product") {
          productHash = Object.fromEntries(newItems.filter((p) => valueHash[p[uploadField[0]]] != null).map((p) => {
            return [p.split_id, valueHash[p[uploadField[0]]]]
          }));
        } else if (field.level == "variant") {
          productHash = Object.fromEntries(newItems.flatMap((p) => { p.variants.forEach((v) => v.product = p); return p.variants; }).filter((v) => valueHash[v[uploadField[0]]] != null).map((v) => {
            return [v.product.split_id, valueHash[v[uploadField[0]]]];
          }));
        }
        newItems = newItems.sort((a,b) => productHash[a.split_id] - productHash[a.split_id]);
        if (resourceType == "variant") {
          newItems = newItems.flatMap((p) => {
            p.variants.forEach((v) => { v.product = p; });
            return p.variants;
          });
        }
        currentTransform((newItems) => {
          setItems(newItems);
          setSelectedItems(newItems);
          setIsLoading(false);
        }, newItems);
      });
    }
  }, [isLoading, resourceType, items, split, setItems, setSelectedItems, setIsLoading, spotAPI, maxSelection, beforeQueryRun]);

  const runQuery = useCallback((query, page) => {
    if (!isLoading) {
      setIsLoading(true);
      var sq = spotAPI.s().allVariants(false).page(page).targets([target]);
      if (query)
        sq = sq.search(query);
      if (split)
        sq.split(split);
      if (beforeQueryRun)
        sq = beforeQueryRun(sq);
      sq.e().done((newItems) => {
        if (resourceType == "variant") {
          newItems = newItems.flatMap((p) => {
            p.variants.forEach((v) => { v.product = p; });
            return p.variants;
          });
        }
        newItems = page === 1 ? newItems : [...(items || []), ...newItems];
        currentTransform((newItems) => {
          setItems(newItems);
          setIsLoading(false);
        }, newItems);
      });
    }
  }, [isLoading, resourceType, items, split, setItems, spotAPI, beforeQueryRun]);

  const setPage = useCallback((page, newquery) => {
    _setPage(page);
    runQuery(newquery !== undefined ? newquery : query, page);
  }, [_setPage, query, runQuery]);

  const setQuery = useCallback((query, reset) => {
    _setQuery(query);
    if (reset)
      setPage(1, query);
    else
      runQuery(query, page);
  }, [runQuery, _setQuery, page, setPage]);

	useEffect(() => {
    if (open && !firstOpen) {
      setFirstOpen(true);
      runQuery(query || "", page);
    }
    if (!open && firstOpen)
      setFirstOpen(false);
	// eslint-disable-next-line
	}, [open, firstOpen, setFirstOpen, runQuery, query]);

  const setSelectedIds = (ids) => {
    if (ids) {
      spotAPI.s().paginate(1000).allVariants(false).targets([target])[identifierField]("in", ids).split(split).e().done(function(items) {
        if (resourceType == "variant")
          setSelectedItems(items.flatMap((i) => i.variants.map((v) => { return { ...v, product: i }; })));
        else
          setSelectedItems(items);
      });
    } else {
      setSelectedItems([]);
    }
  };

	useEffect(() => {
    if (initialSelectionIds)
      setSelectedIds(initialSelectionIds);
	}, [initialSelectionIds, spotAPI]);

	function toggleItem(item) {
    if (!selectedItemHash[item[identifierField]]) {
      if (maxSelection === null || selectedItems.length < maxSelection)
        setSelectedItems([ ...selectedItems, item ]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i[identifierField] != item[identifierField]));
    }
	}

	const scrolledToBottom = useCallback(() => {!isLoading && setPage(page+1)}, [isLoading, setPage, page]);

	if (!open)
    return null;


  return <Modal
    open={true}
    onClose={() => { onCancel({}); setSelectedIds(initialSelectionIds); }}
    title={title || "Add " + titleCase(resourceType)}
    onScrolledToBottom={!isUpload && scrolledToBottom}
    primaryAction={{ disabled: (selectedItems.length === 0 && !allowNoSelection), content: 'Select', onAction: () => { onSelection({ selection: selectedItems }); setSelectedIds(initialSelectionIds); setUploadValue(null); setIsUpload(false); } }}
    secondaryActions={[...(selectMultiple && onClear !== false ? [{ content: 'Clear', onAction: () => { onSelection({ selection: [] }); setSelectedIds(initialSelectionIds);  setUploadValue(null); setIsUpload(false); } }] : []), { content: 'Cancel', onAction: () => { onCancel({}); setSelectedItems([]);  setUploadValue(null); setIsUpload(false); } }]}
    footer={selectedItems.length + (maxSelection !== null ? "/" + maxSelection : "") + " " + resourceType.toLowerCase() + "s selected"}
  >
    {!items && <div style={{ padding: "50px" }}><Stack vertical alignment="center" distribution="center"><Spinner size="large"/></Stack></div>}
    {items && <>
      <Modal.Section>
        {!isUpload && <Stack>
          <Stack.Item fill>
            <Filters
              queryValue={query}
              filters={[]}
              appliedFilters={[]}
              onQueryChange={(val) => setQuery(val, true)}
              onQueryClear={() => setQuery('', true)}
              onClearAll={() => {}}
            />
          </Stack.Item>
          <Button onClick={() => { setIsUpload(true); }}><Icon source={SoftPackMajor}/></Button>
        </Stack>}
        {isUpload && <Stack vertical>
          <Stack><Stack.Item fill><FieldSpecifier field={uploadField} fields={productFields.filter((v) => /(sku|handle|vendor|tag|barcode|id|product_metafield|variant_metafield|product_custom_field|variant_custom_field|variant_id)/i.test(v.handle)) } onChange={(val) => { setUploadField(val);  runUploadQuery(val, uploadValue); }}/></Stack.Item><Button onClick={() => { setIsUpload(false); }}><Icon source={SearchMajor}/></Button></Stack>
          <CachedTextField idle={0.25} multiline={10} value={uploadValue} placeholder={"Please enter a comma or space separated list of your above sepecified attribute."} onChange={(val) => { 
            setUploadValue(val); 
            runUploadQuery(uploadField, val);
          }}/>
        </Stack>}
      </Modal.Section>
      <Modal.Section>
        <MultiTable
          loading={isLoading}
          onRowClick={(row, idx) => toggleItem(items[idx])}
          headings={headings}
          rows={items.map((item) => {
            const image = (spotAPI.getSizedImage(resourceType === "collection" ? item.image : (resourceType == "variant" ? spotAPI.getProductImage(item.product, item) : spotAPI.getProductImage(item)), "64x64"));
            const title = resourceType === "collection" ? item.title : (resourceType == "variant" ?  spotAPI.getProductTitle(item.product, item) : spotAPI.getProductTitle(item));
            return [
              ((maxSelection === null || selectedItems.length < maxSelection || selectedItemHash[item[identifierField]]) ? (
                <Checkbox checked={selectedItemHash[item[identifierField]]} /*onChange={() => toggleItem(item)}*//>) : (<span/>)),
              ...(row ? row(item) : [(image ? (<Thumbnail
                source={image}
                size="medium"
                alt={title}
              />) : <PlaceholderImage/>),
              title
              ])
            ];
          })}
        />
      </Modal.Section></>
    }
  </Modal>;
}
